/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-13 17:06:02
 * @Description: shiguang
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/init';
import './global.scss';
import './styles/antd/replace.scss';
import './config/arms-ali';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import App from './App';
import { platRedirect } from './utils';
dayjs.locale(ja);
platRedirect();
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<App />);
