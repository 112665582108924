import { serviceConfig } from '@/config/request/swaggerServiceConfig';
import { Api as Goods } from './goods';
import { Api as Customer } from './customer';
import { Api as Pay } from './pay';
import { Api as Order } from './order';
import { Api as Market } from './market';
import { Api as OneService } from './oneService';
import { Api as EasyGoods } from './easyGoods';

export const Api = {
    Goods,
    Customer,
    Pay,
    Order,
    Market,
    OneService,
    EasyGoods
};

const warpperServiceConfig = (
    serviceConfig: any,
    ctx: { name: string; basePath: string }
) => {
    const newConfig = { ...serviceConfig };
    if (newConfig.baseURL) {
        newConfig.baseURL = newConfig.baseURL + ctx.basePath;
    }
    return newConfig;
};

const goods = new Goods(
    warpperServiceConfig(serviceConfig, {
        name: 'goods',
        basePath: '/easy/goods'
    })
);
const customer = new Customer(
    warpperServiceConfig(serviceConfig, {
        name: 'customer',
        basePath: '/customer'
    })
);
const pay = new Pay(
    warpperServiceConfig(serviceConfig, { name: 'pay', basePath: '/pay' })
);
const order = new Order(
    warpperServiceConfig(serviceConfig, {
        name: 'order',
        basePath: '/easy/order'
    })
);
const market = new Market(
    warpperServiceConfig(serviceConfig, { name: 'market', basePath: '/market' })
);
const oneService = new OneService(
    warpperServiceConfig(serviceConfig, { name: 'oneService', basePath: '' })
);
const easyGoods = new EasyGoods(
    warpperServiceConfig(serviceConfig, {
        name: 'easyGoods',
        basePath: '/easy/goods'
    })
);

export const apiInstanceList = [
    { key: 'goods', instance: goods },
    { key: 'customer', instance: customer },
    { key: 'pay', instance: pay },
    { key: 'order', instance: order },
    { key: 'market', instance: market },
    { key: 'oneService', instance: oneService },
    { key: 'easyGoods', instance: easyGoods }
];

export const api = {
    goods,
    customer,
    pay,
    order,
    market,
    oneService,
    easyGoods
};
