import { useEffect, useLayoutEffect, useState } from 'react';
import { Menu } from 'antd';
import { useAtom } from 'jotai';
import { jumpPage } from '@/utils';
import { CustomerDetail } from '@/model';
import { ROUTER_BASENAME } from '@/config/base';
import './index.scss';
// eslint-disable-next-line no-duplicate-imports
import { toTheCkb } from '@/config/request/login';
const getMenuTitle = (icon: string, label: string) => {
    return (
        <div className="flex items-center menu-item-warp">
            <img src={icon} alt="" width={20} height={20} />
            <span
                style={{
                    marginLeft: 8
                }}
            >
                {label}
            </span>
        </div>
    );
};
// 规避掉hook只能在react.Fc 中调用;
const useList = () => {
    const list = [
        {
            label: window._$m.t('我的订单'),
            menulink: `${ROUTER_BASENAME}/order/index`
        },
        {
            label: window._$m.t('我的收藏'),
            menulink: `${ROUTER_BASENAME}/collect/index`
        },
        {
            label: window._$m.t('资金钱包'),
            menulink: `${ROUTER_BASENAME}/wallet/index`
        },
        // {
        //     label: window._$m.t('账号管理'),
        //     menulink: toTheCkb('/ja/vip/setupUser')
        // },
        {
            label: window._$m.t('消息通知'),
            menulink: `${ROUTER_BASENAME}/message/index`
        }
    ];
    return {
        list
    };
};
/**
 * 为菜单生成key
 * @returns
 */
const useMenu = () => {
    const { list } = useList();
    const wrapperKey = () => {
        let key = 0;
        return list.map((item: any, index) => {
            const { label } = item;
            key = key + 1;
            return {
                ...item,
                key: key.toString(),
                label: <div className="pl-[8px] text-[16px]"> {label} </div>,
                children: Array.isArray(item.children)
                    ? item.children.map((it, idx) => {
                          const { label } = it;
                          key = key + 1;
                          return {
                              ...it,
                              key: key.toString(),
                              label: <div className="pl-[38px]">{label}</div>
                          };
                      })
                    : undefined
            };
        });
    };
    return wrapperKey();
};
const getMenuItem = (arr, fn) => {
    let item: any = null;
    const _deep = (array) => {
        if (item) return;
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (fn(element)) {
                item = element;
                return;
            }
            if (element?.children?.length) {
                _deep(element?.children);
            }
        }
    };
    _deep(arr);
    return item;
};
// 防止刷新后菜单栏失去高亮，通过路径判断
// 例如http://localhost:3666/collect/index，获取collect判断，返回1
const getMenuHighlight = () => {
    const pathname = window.location.pathname.split('/')[1];
    switch (pathname) {
        case 'order':
            return '1';
        case 'collect':
            return '2';
        case 'wallet':
            return '3';
        case 'message':
            return '5';
        default:
            return '1';
    }
};
const MenuList = () => {
    const [useInfo] = useAtom(CustomerDetail);
    const [openKeys, setOpenKeys] = useState<string[]>();
    // 规避nenus和pathname依赖，进而递归更新
    const pathname = window.location.pathname;
    const [selectedKeys, setSelectedKeys] = useState<string[]>([
        getMenuHighlight()
    ]);
    const menus = useMenu();
    useEffect(() => {
        setSelectedKeys([getMenuHighlight()]);
    }, [pathname]);
    useLayoutEffect(() => {
        const selectedItem = getMenuItem(menus, (item) => {
            if (item.menulink === pathname) {
                return true;
            }
            return false;
        });
        if (!selectedItem) return;
        setSelectedKeys([selectedItem.key]);
        const _item = getMenuItem(menus, (item) => {
            return Boolean(
                item?.children?.some((it) => it.key === selectedItem.key)
            );
        });
        if (_item) setOpenKeys([_item.key]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    return (
        <div
            className={'w-[220px] menu-slider flex-shrink-0 ' + useInfo?.site}
            style={{
                background: '#FBFBFB'
            }}
        >
            <Menu
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                style={{
                    height: '100vh',
                    width: '220px',
                    position: 'fixed',
                    borderRight: 0,
                    overflowY: 'auto'
                }}
                onOpenChange={(_openKeys) => {
                    setOpenKeys(_openKeys);
                }}
                onSelect={({ selectedKeys }) => {
                    const selectedItem = getMenuItem(
                        menus,
                        (item) => item.key === selectedKeys[0]
                    );
                    if (selectedItem?.menulink) {
                        jumpPage(selectedItem.menulink as any);
                    }
                    setSelectedKeys(selectedKeys);
                }}
                mode="inline"
                className="text-left p-0"
                items={menus as any}
            />
        </div>
    );
};
export default MenuList;
