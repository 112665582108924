/*
 * @Author: shiguang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-30 15:51:38
 * @Description: interceptors
 */
import { message } from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';
import Cookie from 'js-cookie';
import { api, apiInstanceList } from '@/service';
import Code from '@/i18n/locales/code.json';
import { Site, getCountryByNavigatorLang } from '@/const';
import { getStationCode, Lang } from '@/i18n/init';
import { logout, toLogin, TokenSignCookie } from './login';
// 项目文件根目录
const baseURL = process.env.REACT_APP_PUBLIC_PATH;

const isProd = process.env.NODE_ENV === 'production';
const axiosV = axios.create({
    timeout: 3 * 60 * 1000,
    baseURL
});

// 请求是否刷新资源
const validVersion = debounce(() => {
    const curVersion = window.curVersion ?? { time: 0 };
    let request = false;
    if (Date.now() > curVersion.time + 10 * 60 * 1000 && !request) {
        request = true;
        axiosV.get(`/compilerInfo.json?t=${Date.now()}`).then((res) => {
            request = false;
            if (
                curVersion.version &&
                curVersion.version !== res?.data?.compilterTime
            ) {
                requestAnimationFrame(() => {
                    window.location.reload();
                });
            }
            curVersion.version = res?.data?.compilterTime;
            curVersion.time = Date.now();
            window.curVersion = curVersion;
        });
    }
}, 1000);

// 白名单code，不进行提示，进行特殊操作
const whiteCode = ['3006'] as string[];
/**
 * 存在cookie的token
 */
export const getToken = async (config) => {
    config.headers = config.headers ?? {};
    const cookieToken = await Cookie.get(TokenSignCookie);
    const localToken = window.localStorage.getItem(`production_route/token`);
    if (cookieToken || localToken) {
        if (config.url?.includes('/changeLoginByType')) {
            return cookieToken;
        }
        return localToken;
    }
};

/**
 * 获取shopId
 * @returns
 */
export const getShopId = () => {
    return window.localStorage.getItem(`production_route/curShop`);
};

/** 根据翻译显示code报错信息 */
const translateByRspCode = (msgCode: string) => {
    /** 后端接口返回code,前端翻译 */
    const stationCode =
        localStorage.getItem('stationCode') || getCountryByNavigatorLang();
    const stationCodeMapLocal =
        {
            [Site.JP]: Lang.ja_JP,
            [Site.KR]: Lang.ko_KR,
            [Site.UK]: Lang.en_GB
        }[stationCode] || '';
    console.log(
        Code[stationCodeMapLocal][msgCode] ?? `【${msgCode}】${Code.noMatch}`
    );
    return (
        Code[stationCodeMapLocal][msgCode] ?? `【${msgCode}】${Code.noMatch}`
    );
};

// 获取站点信息
const stationCode = getStationCode();

// 错误提示
function wrap() {
    const cache: Record<string, boolean> = {};
    function debounce(fn: any) {
        return (msg: string) => {
            if (cache[msg]) return;
            cache[msg] = true;
            fn(msg);
            setTimeout(() => {
                delete cache[msg];
            }, 1000);
        };
    }
    return debounce;
}
export const errorMsgShow = wrap()(message.error.bind(message));

apiInstanceList.forEach((item) => {
    // 请求拦截
    item.instance.instance.interceptors.request.use(async function (config) {
        isProd && validVersion();
        config.headers = config.headers ?? {};
        const isPost = ['POST', 'post'].includes(config.method!);
        const token = await getToken(config);
        const shopId = getShopId();
        if (token) {
            config.headers['X-Authtoken'] = token;
        }
        if (shopId && !config.url?.includes('/changeLoginByType')) {
            config.headers['X-Authshopid'] = shopId;
        }
        if (process.env.REACT_APP_GRAY_TAG) {
            config.headers['X-GRAY-TAG'] = process.env.REACT_APP_GRAY_TAG;
        }
        if (stationCode) {
            config.headers['X-stationCode'] = stationCode;
        }

        if (isPost) {
            if (!config.data && config.params) {
                config.data = config.params;
            }
            config.params = {};
            config.headers['Content-Type'] = 'application/json';
        }
        config.headers['X-GRAY-TAG'] = '20231116-direct-mall2';
        return config;
    });
    // https://test01-s.theckb.com/login
    // 返回拦截
    item.instance.instance.interceptors.response.use(
        function (response) {
            // console.log(`请求url${response.config.url}`, response);
            if (response.data?.code === '0' && response.data.success === true) {
                return response;
            }
            if (response.config.responseType === 'blob') {
                return response;
            }
            // debugger;
            const errorMsg = translateByRspCode(response.data.code);
            if (!whiteCode.includes(response.data.code)) {
                errorMsg && errorMsgShow(errorMsg);
            }
            if (response.data?.code !== '0') {
                if (response.data?.code === '10000000') {
                    // window.location.href = toLogin();
                }
                return Promise.resolve({
                    ...response,
                    data: { ...response.data, success: false }
                });
            }
            return Promise.reject(errorMsg);
        },
        function (error) {
            errorMsgShow(String(error));
            return Promise.reject(error);
        }
    );
});

export const request = api;
