import { atom, useAtom } from 'jotai';
const showModal = atom<boolean>(false);
const showModalVal = atom((get) => get(showModal));
const writeShowModal = atom(null, (_, set, update: boolean) => {
    // `update` is any single value we receive for updating this atom
    set(showModal, update);
});
const getShowModal = () => showModalVal;
export default {
    showModal,
    getShowModal,
    writeShowModal
};
