import { atom, useAtom } from 'jotai';
const messageLength = atom<number>(0);
const messageLengthVal = atom((get) => get(messageLength));
const writeMessageLength = atom(null, (_, set, update: number) => {
    // `update` is any single value we receive for updating this atom
    set(messageLength, update);
});
const getMessageLength = () => messageLengthVal;
export default {
    messageLength,
    getMessageLength,
    writeMessageLength
};
