/*
 * @Date: 2023-10-23 10:31:16
 * @LastEditors: lihwang
 * @LastEditTime: 2023-10-23 10:43:51
 * @FilePath: /ckb-mall-web/src/model/loading.ts
 * @Description:
 * @Author: lihwang
 */
import { atom, useAtom } from 'jotai';
const gloading = atom<boolean>(false);
const loadVal = atom((get) => get(gloading));
const writeLoading = atom(null, (_, set, update: boolean) => {
    // `update` is any single value we receive for updating this atom
    set(gloading, update);
});
const getLoading = () => loadVal as unknown as boolean;
export default {
    gloading,
    getLoading,
    writeLoading
};
