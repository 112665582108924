/*
 * @Date: 2023-11-08 16:07:08
 * @LastEditors: lihwang
 * @LastEditTime: 2024-01-16 19:16:42
 * @FilePath: /ckb-mall-web/src/common/CKBLayout/modules/ToAppModal/index.tsx
 * @Description:
 * @Author: lihwang
 */
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useRequest, useLocalStorageState } from 'ahooks';
import { atom, useAtom } from 'jotai';
import { PopupRespDTO } from '@/service/market';
import { request } from '@/config/request';
import CancelCircle from '@/common/icons/CancelCircle';
import { ShowModal } from '@/model';
import { urlAddChannel } from '@/utils';
import styles from './index.scss';

// 首页弹窗
const ToAppModal = () => {
    const [showModal, writeShowModal] = useAtom(ShowModal.showModal);
    return (
        <Modal
            title=""
            open={showModal}
            classNames={{
                header: styles.model_header,
                content: styles.ant_modal_content
            }}
            onCancel={() => {
                writeShowModal(false);
            }}
            width={770}
            footer={null}
            className={styles.home_modal}
            closeIcon={<CancelCircle size={20} />}
            styles={{
                content: {
                    borderRadius: '20px'
                }
            }}
        >
            <img
                src="https://static-jp.theckb.com/static-asset/easy-h5/toAPP.png"
                style={{ width: '770px' }}
                alt=""
            />
        </Modal>
    );
};
export default ToAppModal;
