import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.scss';
const AgentToDirectLoading = () => {
    const [urlParams, setSearchParams] = useSearchParams();
    // 代采跳直营的中转loading,展示1.5s后去除
    useEffect(() => {
        if (urlParams.get('agentToDirect') === 'true') {
            setTimeout(() => {
                setSearchParams({
                    agentToDirect: ''
                });
            }, 1500);
        }
    }, [setSearchParams, urlParams]);
    return (
        <>
            {urlParams.get('agentToDirect') === 'true' && (
                <div className="agentToDirectLoading">
                    <div className="bg">
                        <img
                            style={{
                                width: 567
                            }}
                            src="https://static-s.theckb.com/BusinessMarket/Easy/PC/agentToDirect_loading.png"
                            alt=""
                        />
                        <img
                            className="airport"
                            src="https://static-s.theckb.com/BusinessMarket/Client/d2dLoading_icon.png"
                            alt=""
                        />
                    </div>
                </div>
            )}
        </>
    );
};
export default AgentToDirectLoading;
