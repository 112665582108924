/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** BackAddTaskReqDTO */
export interface BackAddTaskReqDTO {
    bizType?: string;
    businessName?: string;
    /** @format int32 */
    businessType?: number;
    /** @format date-time */
    endTime?: string;
    extend?: string;
    /** @format int32 */
    failNum?: number;
    fileName?: string;
    languageCode?: string;
    message?: string;
    /** @format int64 */
    operatorId?: number;
    operatorName?: string;
    paramText?: string;
    /** @format date-time */
    startTime?: string;
    /** @format int32 */
    status?: number;
    /** @format int32 */
    successNum?: number;
    templateCode?: string;
    url?: string;
    warehouseCode?: string;
}

/** BaseTaskDto */
export interface BaseTaskDto {
    /**
     * 任务id
     * @format int64
     */
    taskId?: number;
}

/** ExportReqDTO */
export interface ExportReqDTO {
    bizType?: string;
    businessName?: string;
    dataSourceType?: 'MySql' | 'OTS';
    extend?: string;
    fileName?: string;
    languageCode?: string;
    /** @format int64 */
    operatorId?: number;
    operatorName?: string;
    paramText?: string;
    sql?: string;
    warehouseCode?: string;
}

/** ExportRespDTO */
export interface ExportRespDTO {
    url?: string;
}

/** ExportTemplateContentReqDTO */
export interface ExportTemplateContentReqDTO {
    languageCode?: string;
    tableHeaders?: string[];
}

/** ExportTemplateReqDTO */
export interface ExportTemplateReqDTO {
    contents?: ExportTemplateContentReqDTO[];
    name?: string;
}

/** ISortFieldEnum */
export type ISortFieldEnum = object;

/** ImportExportTaskDTO */
export interface ImportExportTaskDTO {
    bizType?: string;
    businessName?: string;
    /** @format int32 */
    businessType?: number;
    /** @format date-time */
    endTime?: string;
    extend?: string;
    /** @format int32 */
    failNum?: number;
    fileName?: string;
    /** @format int64 */
    id?: number;
    languageCode?: string;
    message?: string;
    /** @format int64 */
    operatorId?: number;
    operatorName?: string;
    paramText?: string;
    /** @format date-time */
    startTime?: string;
    /** @format int32 */
    status?: number;
    /** @format int32 */
    successNum?: number;
    templateCode?: string;
    url?: string;
}

/** ImportReqDTO */
export interface ImportReqDTO {
    bizType?: string;
    businessName?: string;
    extend?: string;
    fileUrl?: string;
    languageCode?: string;
    /** @format int64 */
    operatorId?: number;
    operatorName?: string;
    paramText?: string;
    warehouseCode?: string;
}

/** ImportRespDTO */
export interface ImportRespDTO {
    /** @format int64 */
    taskId?: number;
}

/** ImportTableHeaderDto */
export interface ImportTableHeaderDto {
    dbColumns?: string;
    dbType?: string;
    label?: string;
}

/** ImportTemplateContentReqDTO */
export interface ImportTemplateContentReqDTO {
    languageCode?: string;
    tableHeaders?: ImportTableHeaderDto[];
}

/** ImportTemplateReqDTO */
export interface ImportTemplateReqDTO {
    contents?: ImportTemplateContentReqDTO[];
    name?: string;
    /** @format int32 */
    retentionDays?: number;
}

/** RunImportTaskReqDTO */
export interface RunImportTaskReqDTO {
    /** @format int64 */
    taskId?: number;
}

/** RunImportTaskRespDTO */
export interface RunImportTaskRespDTO {
    bizType?: string;
    databaseName?: string;
    errorMessage?: string;
    extend?: string;
    paramText?: string;
    success?: boolean;
    tableName?: string;
    /** @format int32 */
    total?: number;
}

/** TaskPageDto */
export interface TaskPageDto {
    /**
     * 业务类型0-导入；1-导出
     * @format int32
     */
    businessType?: number;
    /**
     * 创建结束时间
     * @format date-time
     */
    createEndTime?: string;
    /**
     * 创建开始时间
     * @format date-time
     */
    createStartTime?: string;
    /** 操作人名 */
    operatorName?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    sorts?: ISortFieldEnum[];
    /**
     * 状态(0-等待中 1-处理中 2-成功 3-失败 4-部分成功)
     * @format int32
     */
    status?: number;
    /** 仓库code--给仓库使用，仅展示当前登录仓库的数据 */
    warehouseCode?: string;
}

/** UpdateTaskReqDTO */
export interface UpdateTaskReqDTO {
    /** @format int32 */
    failNum?: number;
    message?: string;
    statusEnum?: 'FAIL' | 'PARTIAL_SUCCESS' | 'PROCESSING' | 'SUCCESS' | 'WAITING';
    /** @format int32 */
    successNum?: number;
    /** @format int64 */
    taskId?: number;
}

/** BizResponse«ExportRespDTO» */
export interface BizResponseExportRespDTO {
    code?: string;
    data?: ExportRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«ImportExportTaskDTO» */
export interface BizResponseImportExportTaskDTO {
    code?: string;
    data?: ImportExportTaskDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«ImportRespDTO» */
export interface BizResponseImportRespDTO {
    code?: string;
    data?: ImportRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«PageResult«ImportExportTaskDTO»» */
export interface BizResponsePageResultImportExportTaskDTO {
    code?: string;
    data?: PageResultImportExportTaskDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«RunImportTaskRespDTO» */
export interface BizResponseRunImportTaskRespDTO {
    code?: string;
    data?: RunImportTaskRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
    code?: string;
    /** @format int64 */
    data?: number;
    msg?: string;
    success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
    code?: string;
    data?: object;
    msg?: string;
    success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
    code?: string;
    data?: string;
    msg?: string;
    success?: boolean;
}

/** PageResult«ImportExportTaskDTO» */
export interface PageResultImportExportTaskDTO {
    /** @format int64 */
    current?: number;
    records?: ImportExportTaskDTO[];
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
    /** @format int64 */
    totalPage?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443'
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path
            })
            .then((response) => response.data);
    };
}

/**
 * @title oneService-API测试文档
 * @version v1.1.0
 * @baseUrl //master-gateway.theckb.com:443
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙测试文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    oneService = {
        /**
         * No description
         *
         * @tags 健康检查探针
         * @name ProbeTest
         * @summary 执行一次健康检查探针
         * @request GET:/oneService/Probe/test
         */
        probeTest: (params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/oneService/Probe/test`,
                method: 'GET',
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskAsyncExport
         * @summary asyncExport
         * @request POST:/oneService/task/asyncExport
         */
        taskAsyncExport: (dto: ExportReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseLong, any>({
                path: `/oneService/task/asyncExport`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskAsyncImport
         * @summary asyncImport
         * @request POST:/oneService/task/asyncImport
         */
        taskAsyncImport: (dto: ImportReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseLong, any>({
                path: `/oneService/task/asyncImport`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskBackAddRecord
         * @summary addRecord
         * @request POST:/oneService/task/back/addRecord
         */
        taskBackAddRecord: (dto: BackAddTaskReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/oneService/task/back/addRecord`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskCreateImportTask
         * @summary createImportTask
         * @request POST:/oneService/task/createImportTask
         */
        taskCreateImportTask: (dto: ImportReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseImportRespDTO, any>({
                path: `/oneService/task/createImportTask`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskDownloadExportData
         * @summary 下载数据-带权限
         * @request POST:/oneService/task/downloadExportData
         */
        taskDownloadExportData: (dto: BaseTaskDto, params: RequestParams = {}) =>
            this.request<BizResponseString, any>({
                path: `/oneService/task/downloadExportData`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskDownloadImportFailData
         * @summary 下载导入失败记录
         * @request POST:/oneService/task/downloadImportFailData
         */
        taskDownloadImportFailData: (dto: BaseTaskDto, params: RequestParams = {}) =>
            this.request<BizResponseString, any>({
                path: `/oneService/task/downloadImportFailData`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskExport
         * @summary export
         * @request POST:/oneService/task/export
         */
        taskExport: (dto: ExportReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseExportRespDTO, any>({
                path: `/oneService/task/export`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskImportRunTask
         * @summary runImportTask
         * @request POST:/oneService/task/import/runTask
         */
        taskImportRunTask: (dto: RunImportTaskReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseRunImportTaskRespDTO, any>({
                path: `/oneService/task/import/runTask`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskPage
         * @summary 分页查询
         * @request POST:/oneService/task/page
         */
        taskPage: (dto: TaskPageDto, params: RequestParams = {}) =>
            this.request<BizResponsePageResultImportExportTaskDTO, any>({
                path: `/oneService/task/page`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskSelectById
         * @summary selectById
         * @request GET:/oneService/task/selectById
         */
        taskSelectById: (
            query: {
                /**
                 * taskId
                 * @format int64
                 */
                taskId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseImportExportTaskDTO, any>({
                path: `/oneService/task/selectById`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags import-export-task-controller
         * @name TaskUpdateTask
         * @summary updateTask
         * @request POST:/oneService/task/updateTask
         */
        taskUpdateTask: (dto: UpdateTaskReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/oneService/task/updateTask`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags template-controller
         * @name TemplateCreateExportTemplate
         * @summary createExportTemplate
         * @request POST:/oneService/template/createExportTemplate
         */
        templateCreateExportTemplate: (dto: ExportTemplateReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseString, any>({
                path: `/oneService/template/createExportTemplate`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags template-controller
         * @name TemplateCreateImportTemplate
         * @summary createImportTemplate
         * @request POST:/oneService/template/createImportTemplate
         */
        templateCreateImportTemplate: (dto: ImportTemplateReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseString, any>({
                path: `/oneService/template/createImportTemplate`,
                method: 'POST',
                body: dto,
                type: ContentType.Json,
                ...params
            })
    };
}
