import { atom, useAtom } from 'jotai';
const cartLength = atom<number>(0);
const cartLengthVal = atom((get) => get(cartLength));
const writeCartLength = atom(null, (_, set, update: number) => {
    // `update` is any single value we receive for updating this atom
    set(cartLength, update);
});
const getCartLength = () => cartLengthVal;
export default {
    cartLength,
    getCartLength,
    writeCartLength
};
