/*
 * @Date: 2023-10-10 14:22:28
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-13 20:55:53
 * @FilePath: /ckb-mall-web/src/config/router.tsx
 * @Description:
 * @Author: lihwang
 */
import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { Navigate } from 'react-router-dom';

export interface RouteItem {
    /**
     * 路由对应的页面
     */
    component?: LazyExoticComponent<ComponentType<any>> | ComponentType<any>;
    /**
     * 路由地址
     */
    path?: string;
    /**
     * 父级路由，用于menu的高亮判断，例如 detail 页面 要定位到 列表页面 菜单上
     */
    activePath?: string;
    /**
     * 默认false，如果为true时，需要和路由相同时才能匹配，但是如果有斜杠也是可以匹配上的。
     */
    exact?: boolean;
    /**
     * 是否严格匹配斜杠结尾
     */
    strict?: boolean;
    /**
     * 页面标题
     */
    title?: string;
    /**
     * 显示左侧菜单，默认为true
     */
    showMenu?: boolean;
    /**
     * 显示头部，默认为true，如果设为false，则无头部组件
     */
    showHeader?: boolean;
    /** 页面权限code */
    pagePermissionCode?: string;
    /** 操作权限code */
    actionPermissionCode?: string[];
}

const router: RouteItem[] = [
    {
        path: '/',
        title: '首页',
        component: () => <Navigate to={'/home'} />,
        showMenu: false
    },
    {
        path: '/home',
        title: '首页',
        component: lazy(() => import('../container/home')),
        showMenu: false
    },
    {
        path: '/goodsList',
        title: '商品列表',
        component: lazy(() => import('../container/goodsList')),
        showMenu: false
    },
    {
        path: '/hotSellingList',
        title: '爆款火拼列表',
        component: lazy(() => import('../container/goodsList/HotSelling')),
        showMenu: false
    },
    {
        path: '/settlement',
        title: '商品结算',
        component: lazy(() => import('../container/settlement')),
        showMenu: false
    },
    {
        path: '/jumpPage',
        title: '跳转页',
        component: lazy(() => import('../container/jumpPage')),
        showMenu: false
    },
    {
        path: '/order/index',
        title: '我的订单',
        component: lazy(() => import('../container/order/List')),
        showMenu: true
    },
    {
        path: '/order/detail',
        title: '订单详情',
        component: lazy(() => import('../container/order/Detail')),
        showMenu: true
    },
    {
        path: '/collect/index',
        title: '我的收藏',
        component: lazy(() => import('../container/collect/index')),
        showMenu: true
    },
    {
        path: '/message/index',
        title: '消息通知',
        component: lazy(() => import('../container/message/index')),
        showMenu: true
    },
    {
        path: '/message/detail',
        title: '消息详情',
        component: lazy(() => import('../container/message/Detail')),
        showMenu: true
    },
    {
        path: '/wallet/index',
        title: '资金钱包',
        component: lazy(() => import('../container/wallet/index/Index')),
        showMenu: true
    },
    {
        path: '/wallet/recharge',
        title: '充值页',
        component: lazy(() => import('../container/wallet/recharge/Index')),
        showMenu: true
    },
    {
        path: '/goods',
        title: '商品详情',
        component: lazy(() => import('../container/good/Index')),
        showMenu: false
    },
    {
        path: '/cart',
        title: '购物车',
        component: lazy(() => import('../container/cart/index')),
        showMenu: false
    },
    {
        path: '/clause',
        title: '条款',
        component: lazy(() => import('../container/clause/Index')),
        showMenu: false
    },
    {
        path: '/page404',
        title: '404',
        component: lazy(() => import('../container/other/Page404/Index')),
        showMenu: false
    },
    {
        path: '/*',
        title: '迷失方向',
        component: lazy(() => import('../container/other/OtherPage'))
    }
];
export default router;
