/*
 * @Author: shiguang
 * @Date: 2023-04-26 10:48:59
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-03 17:27:36
 * @Description: 静态地址
 */
export const CDN_HOST = 'https://static-s.theckb.com';
export const PC_CDN_HOST =
    'https://static-s.theckb.com/BusinessMarket/Easy/PC/';
export const STATIC_URL = {};
// 商品兜底图
export const BACK_UP_IMG =
    'https://static-s.theckb.com/BusinessMarket/Easy/PC/ckb_backup.png';
