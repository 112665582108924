/* eslint-disable new-cap */
/*
 * @Author: liangliang
 * @Date: 2023-06-02 11:37:56
 * @LastEditors: lihwang
 * @LastEditTime: 2024-01-22 17:47:33
 * @Description: 悬浮右侧菜单
 */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './index.scss';
import { useAtom } from 'jotai';
import { CDN_HOST } from '@/const/staticURL';
import { CustomerDetail } from '@/model';
const FloatToolbar = () => {
    const [isExpand, setIsExpand] = useState(true);
    const [msgCount, setMsgCount] = useState<number>(0);
    const [useInfo] = useAtom(CustomerDetail);
    const navigate = useNavigate();

    // 初始化聊天弹窗
    const initChannelIO = () => {
        if (!window.ChannelIO) {
            return;
        }
        window?.ChannelIO('boot', {
            pluginKey: '6ada065d-08b5-4f42-9131-302ff4e4e772',
            profile: {
                systemName: null,
                systemEmail: null,
                templateName: null,
                systemMemberId: null,
                name: null,
                email: null
            }
        });
        window?.ChannelIO('hideChannelButton');
        window?.ChannelIO('onBadgeChanged', ((num: number) => {
            setMsgCount(num);
        }) as any);
    };
    useEffect(() => {
        setTimeout(() => {
            initChannelIO();
        }, 5000);
    }, []);

    // 显示聊天弹窗
    const viewTerms = () => {
        navigate(`/clause`);
    };

    // 显示聊天弹窗
    const showChannelIO = () => {
        const w: any = window;
        const ChannelIO = w.ChannelIO;
        // 根据站点判断展示哪个语言
        // {language: 'ko' | 'ja' | 'en'}
        const language = 'ja';
        // eslint-disable-next-line new-cap
        ChannelIO('updateUser', {
            language
        });
        // eslint-disable-next-line new-cap
        ChannelIO('showMessenger');
    };
    return (
        <aside className={`m-tool-bar ${isExpand ? 'tool-bar-show' : ''}`}>
            <ul>
                <li className="tool-bar-Statute">
                    <Link
                        className={isExpand ? 'bg-white' : ''}
                        to="/clause"
                        target="_blank"
                    >
                        <img
                            referrerPolicy="no-referrer"
                            draggable="false"
                            src={`https://static-jp.theckb.com/static-asset/easy-h5/clause.svg`}
                            alt=""
                        />
                        <span>{window._$m.t('规约')}</span>
                    </Link>
                    <div className="tool-bar-Divider" />
                </li>

                <li className="tool-bar-service">
                    <a
                        className={isExpand ? 'bg-white' : ''}
                        onClick={() => showChannelIO()}
                    >
                        <img
                            referrerPolicy="no-referrer"
                            draggable="false"
                            src={`${CDN_HOST}/BusinessMarket/icon/icon_serve%402x.png`}
                            alt=""
                        />
                        <span>{window._$m.t('客服')}</span>
                        {msgCount ? (
                            <div className="msg-count">{msgCount} </div>
                        ) : null}
                    </a>
                </li>
                <li className="tool-bar-menu">
                    {isExpand ? (
                        <img
                            className="tool-bar-icon-menu"
                            draggable="false"
                            src={`${CDN_HOST}/BusinessMarket/icon/icon_close%402x.png`}
                            alt=""
                            onClick={() => setIsExpand(false)}
                        />
                    ) : (
                        <img
                            className="tool-bar-icon-menu"
                            draggable="false"
                            src={`${CDN_HOST}/BusinessMarket/icon/icon_open%402x.png`}
                            alt=""
                            onClick={() => setIsExpand(true)}
                        />
                    )}
                </li>
            </ul>
        </aside>
    );
};
export default FloatToolbar;
