/*
 * @Date: 2023-11-09 14:10:59
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-20 17:45:21
 * @FilePath: /ckb-mall-web/src/config/arms-ali.ts
 * @Description:
 * @Author: lihwang
 */

import Logger from 'alife-logger';

if (process.env.NODE_ENV === 'production') {
    const __bl = Logger.singleton({
        pid: process.env.REACT_APP_ARMS_PID,
        appType: 'web',
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        // 环境
        environment: process.env.REACT_APP_ENV
    });

    // 禁用API自动上报
    __bl.setConfig({
        disableHook: true
    });
    // 忽略指定URL/API/JS错误。符合规则的日志将被忽略且不会被上报，包含子配置项ignoreUrls、ignoreApis、ignoreErrors和ignoreResErrors。
    __bl.setConfig({
        ignore: {
            // 忽略某些URL
            ignoreUrls: [
                function (str: string) {
                    // 上报
                    return false;
                }
            ],
            // 忽略某些API
            ignoreApis: [
                function (str: string) {
                    // 上报
                    return false;
                }
            ],
            // 忽略某些JS错误
            ignoreErrors: [
                function (str: string) {
                    console.log('ams error', str);
                    if (
                        /[ResizeObserver\sloop] | [reading\s'data'] | [reading\s'success'] | [evaluating\s'e.data'] | [evaluating\s'e.success'] | [n is not defined] | [Network\sError]/.test(
                            str
                        )
                    )
                        return true;
                    // 上报
                    return false;
                }
            ],
            // 忽略指定的资源错误
            ignoreResErrors: [
                function (str: string) {
                    // 上报
                    return false;
                }
            ]
        }
    });
    // 自定义字段
}
