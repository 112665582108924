/*
 * @Author: shiguang
 * @Date: 2023-07-04 22:27:10
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-07 16:31:34
 * @Description: Description
 */

import qs from 'query-string';
import { createContext } from 'react';
import extractConfig from '@/i18n/locales/extractConfig.json';
import jp from '@/i18n/locales/ja_JP.json';
import kr from './locales/ko_KR.json';
import en from './locales/en_GB.json';
import zh from './locales/zh_CN.json';

/** */
export enum Local {
    ZH = 'zh',
    /** 日文 */
    JA = 'ja',
    /** 韩文 */
    KO = 'ko',
    /** 英文 */
    EN = 'en'
}

/** */
export enum Lang {
    zh_CN = 'zh_CN',
    /** 日文 */
    ja_JP = 'ja_JP',
    /** 韩文 */
    ko_KR = 'ko_KR',
    /** 英文 */
    en_GB = 'en_GB'
}

/**
 * 国家及货币单位
 */
export enum Site {
    /**
     * 日本站
     */
    JA = 'JapanStation',
    /**
     * 韩国站
     */
    KO = 'KoreaStation',
    /**
     * 英国站
     */
    EN = 'UkStation'
}

type StationCode = `${Site}`;

/** 通过站点获取信息 */
export const getLangByStationCode = (stationCode: StationCode): Lang => {
    return (
        {
            [Site.KO]: Lang.ko_KR,
            [Site.JA]: Lang.ja_JP,
            [Site.EN]: Lang.en_GB
        }[stationCode] || Lang.ja_JP
    );
};

const getQuerylang = (): Lang => {
    const query = qs.parse(window.location.search) as { lang: Local };

    return {
        [Local.ZH]: Lang.zh_CN,

        [Local.JA]: Lang.ja_JP,

        [Local.KO]: Lang.ko_KR,

        [Local.EN]: Lang.en_GB
    }[query.lang];
};

const getOldLocalLang = () => {
    const lang = localStorage.getItem('__lang__') || Local.JA;
    return (
        {
            [Local.ZH]: Lang.zh_CN,

            [Local.JA]: Lang.ja_JP,

            [Local.KO]: Lang.ko_KR,

            [Local.EN]: Lang.en_GB
        }[lang] || Lang.ja_JP
    );
};
export const getLang = () => {
    const queryLang = getQuerylang();
    if (queryLang) {
        localStorage.setItem('lang', queryLang);
        const _queryLang = {
            [Lang.zh_CN]: Local.ZH,
            [Lang.ja_JP]: Local.JA,
            [Lang.ko_KR]: Local.KO,
            [Lang.en_GB]: Local.EN
        }[queryLang];

        localStorage.setItem('__lang__', _queryLang);
        return queryLang;
    }

    const oldLocalLang = getOldLocalLang();
    if (oldLocalLang) {
        localStorage.setItem('lang', oldLocalLang);
        return oldLocalLang;
    }
    const lang = localStorage.getItem('lang');
    if (lang) {
        return lang;
    }
    localStorage.setItem('lang', Lang.ja_JP);
    return Lang.ja_JP;
};
// const initLang = getLang();
const initLang = Lang.ja_JP;

/**
 * 获取站点信息
 * @returns
 */
export const getStationCode = () => {
    return Site[initLang] || Site.JA;
};

export const LocalContext = createContext(initLang);

window._$m = {
    t: (text: string, options: { data?: Record<string, any> } = {}): string => {
        const { pages, common } = extractConfig;
        const urlPathname = window.location.pathname;

        const item = pages
            .map((item) => {
                return {
                    ...item,
                    linkTo: [...(item.linkTo ?? []), item.path]
                };
            })
            .find((item) => item.urlPathname === urlPathname)!;
        const ja_JP = window.g_locales
            ? window.g_locales[initLang]
            : window._$m[initLang] ?? {};
        const commonTranslates = common.reduce((pre, cur) => {
            return {
                ...pre,
                ...(ja_JP?.[cur.path] ?? {})
            };
        }, {} as any);
        const linkTo = item?.linkTo ?? [];
        const getConfig = linkTo.reduce((pre, cur) => {
            return {
                ...commonTranslates,
                ...pre,
                ...ja_JP?.[cur]
            };
        }, {} as any);
        let lastText = ['', undefined].includes(getConfig[text])
            ? text
            : (getConfig[text] as string);

        if (options.data) {
            for (const key in options.data) {
                if (Object.prototype.hasOwnProperty.call(options.data, key)) {
                    const value = options.data[key];
                    const templateStr = `{{${key}}}`;
                    lastText = lastText.replace(templateStr, value as string);
                }
            }
        }
        return lastText;
    },
    [Lang.ja_JP]: jp,
    [Lang.ko_KR]: kr,
    [Lang.en_GB]: en,
    [Lang.zh_CN]: zh
};

export const getUnTranslateData = (data) => {
    return Object.entries(data).reduce((pre, cur: any) => {
        const [key, config] = cur;
        pre[key] = Object.entries(config).reduce((_pre, _cur) => {
            const [_key, _value] = _cur;
            if ('' === _value) {
                _pre[_key] = '';
            }
            return _pre;
        }, {});
        return pre;
    }, {});
};

export const mergeDataByOtherConfig = (data, otherConfig) => {
    return Object.entries(data).reduce((pre, cur: any) => {
        const [key, config] = cur;
        pre[key] = Object.entries(config).reduce((_pre, _cur) => {
            const [_key, _value] = _cur;
            if (_value === '' && otherConfig[_key]) {
                _pre[_key] = otherConfig[_key];
            } else {
                _pre[_key] = _value;
            }
            return _pre;
        }, {});
        return pre;
    }, {});
};
