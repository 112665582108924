/*
 * @Author: lihwang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: lihwang
 * @LastEditTime: 2024-01-16 18:50:16
 * @Description: interceptors
 */
import Cookie from 'js-cookie';
import md5 from 'crypto-js/md5';
import queryString from 'query-string';
import { api } from '@/service';
import Code from '@/i18n/locales/code.json';
import { jumpPage, urlAddChannel } from '@/utils';
import { ENUM_PAGE } from '@/const/enum';
import { CountriesEnum, getCountryByNavigatorLang, Site } from '@/const';
import { getStationCode, Lang } from '@/i18n/init';
import { errorMsgShow } from './interceptors';

// 登录环境
type loginEnv = 'dev' | 'test' | 'pre' | 'prod' | 'prod1';

/**
 * 存在cookie的token名称
 */
export const TokenSignCookie: string =
    process.env.REACT_APP_ENV + '_route/token';
/**
 * 存储cookie的环境
 */
export const cookieEnv =
    process.env.NODE_ENV === 'production' ? '.theckb.com' : 'localhost';

// const ChangeLoginInstance = api.customer.instance;

// /** 根据翻译显示code报错信息 */
// const translateByRspCode = (msgCode: string) => {
//     /** 后端接口返回code,前端翻译 */
//     const stationCode =
//         localStorage.getItem('stationCode') || getCountryByNavigatorLang();
//     const stationCodeMapLocal =
//         {
//             [Site.JP]: Lang.ja_JP,
//             [Site.KR]: Lang.ko_KR,
//             [Site.UK]: Lang.en_GB
//         }[stationCode] || '';
//     console.log(
//         Code[stationCodeMapLocal][msgCode] ?? `【${msgCode}】${Code.noMatch}`
//     );
//     return (
//         Code[stationCodeMapLocal][msgCode] ?? `【${msgCode}】${Code.noMatch}`
//     );
// };

// // 获取站点信息
// const stationCode = getStationCode();

// // 请求拦截
// ChangeLoginInstance.interceptors.request.use(async function (config) {
//     config.headers = config.headers ?? {};
//     const cookieToken = await Cookie.get(TokenSignCookie);
//     const localToken = window.localStorage.getItem(`production_route/token`);
//     if (cookieToken || localToken) {
//         if (config.url?.includes('/changeLoginByType')) {
//             console.log(cookieToken);
//             config.headers['X-Authtoken'] = cookieToken;
//         } else {
//             config.headers['X-Authtoken'] = localToken || cookieToken;
//         }
//     }
//     config.headers['X-GRAY-TAG'] = '20231116-direct-mall2';
//     return config;
// });

// // 返回拦截
// ChangeLoginInstance.interceptors.response.use(
//     function (response) {
//         // console.log(`请求url${response.config.url}`, response);
//         if (response.data?.code === '0' && response.data.success === true) {
//             return response;
//         }
//         const errorMsg = translateByRspCode(response.data.code);
//         errorMsg && errorMsgShow(errorMsg);

//         return Promise.reject();
//     },
//     function (error) {
//         return Promise.reject(error);
//     }
// );

interface MockLogin {
    password: string;
    nameOrEmail: string;
}

export const getLoginToken = async ({ password, nameOrEmail }: MockLogin) => {
    logout();
    const loginRes = await api.customer.passwordLogin.passwordLogin({
        password: md5(password).toString(),
        nameOrEmail,
        platformType: 1
    });

    if (loginRes.data?.token) {
        Cookie.set(TokenSignCookie, loginRes.data?.token ?? '', {
            path: '/',
            domain: cookieEnv
        });
        const res = await api.customer.changeLoginByType.changeLoginByType({
            type: 3
        });
        const fistShopId =
            res.data?.customerShopList?.[0]?.customerShopId?.toString() || '';
        window.localStorage.setItem(
            'production_route/token',
            res.data?.token || ''
        );
        window.localStorage.setItem('production_route/curShop', fistShopId);
        if (res.data?.token) return res.data?.token;
    }
    return '';
};

export const checkLogin = async (local: boolean = true) => {
    const token = window.localStorage.getItem('production_route/token');
    const cookieToken = Cookie.get(TokenSignCookie);
    if (cookieToken) {
        if (token && local) {
            return token;
        }
        try {
            const res = await api.customer.changeLoginByType.changeLoginByType({
                type: 3
            });
            const fistShopId =
                res.data?.customerShopList?.[0]?.customerShopId?.toString() ||
                '';
            window.localStorage.setItem(
                'production_route/token',
                res.data?.token || ''
            );
            window.localStorage.setItem('production_route/curShop', fistShopId);
            return res.data?.token;
        } catch (error) {
            logout();
        }
    }
    logout();
    return '';
};

/**
 * 判断是否登录
 * @returns
 */
export const isLogin = () => {
    return !!(
        window.localStorage.getItem('production_route/token') &&
        Cookie.get(TokenSignCookie)
    );
};

/** 退出清除信息 */
export const logout = () => {
    window.localStorage.removeItem('production_route/curShop');
    window.localStorage.removeItem('production_route/token');
    Cookie.remove(TokenSignCookie, { domain: cookieEnv });
};

/** 去登录 */
export const toLogin = (
    redirect: string | undefined = window.location.href,
    path = '/login'
) => {
    logout();
    const env = process.env.REACT_APP_ENV as loginEnv;
    if (env === 'dev') {
        return ENUM_PAGE.LOGIN;
    }
    const envUrl = {
        dev: 'http://localhost:8081',
        test: 'https://test01-s.theckb.com',
        pre: 'https://pre-s.theckb.com',
        prod: 'https://s.theckb.com'
    };
    const npath = (envUrl[env] ?? 'https://s.theckb.com') + path;
    const tourl = redirect
        ? `${npath}?from=${encodeURIComponent(redirect)}`
        : npath;
    return urlAddChannel(tourl);
};

/** 去注册 */
export const toSignUp = (
    redirect: string | undefined = window.location.href,
    path = '/signup'
) => {
    logout();
    const env = process.env.REACT_APP_ENV as loginEnv;
    if (env === 'dev') {
        return ENUM_PAGE.LOGIN;
    }
    const envUrl = {
        dev: 'http://localhost:8081',
        test: 'https://test01-s.theckb.com',
        pre: 'https://pre-s.theckb.com',
        prod: 'https://s.theckb.com'
    };
    const npath = (envUrl[env] ?? 'https://s.theckb.com') + path;
    const tourl = redirect
        ? `${npath}?from=${encodeURIComponent(redirect)}`
        : npath;
    return urlAddChannel(tourl);
};
// 跳转待采地址
export const toTheCkb = (path = '/') => {
    const env = process.env.REACT_APP_ENV as loginEnv;
    const envUrl = {
        dev: 'http://localhost:8081',
        test: 'https://test01-s.theckb.com',
        pre: 'https://pre-s.theckb.com',
        prod: 'https://s.theckb.com'
    };
    const npath = (envUrl[env] ?? 'https://s.theckb.com') + path;
    return urlAddChannel(npath);
};

// 跳转h5直营地址
const { channel } = queryString.parse(window.location.search);
export const toH5TheCkb = (path = '/') => {
    const env = process.env.REACT_APP_ENV as loginEnv;
    const envUrl = {
        test: 'https://test-m.3fbox.com',
        pre: 'https://pre-m.3fbox.com',
        prod: 'https://m.3fbox.com'
    };
    let npath = (envUrl[env] ?? 'https://m.3fbox.com') + path;
    if (channel) {
        npath = envUrl[env]
            ? envUrl[env] + `?channel=${channel}`
            : 'https://test-m.3fbox.com' + path + `?channel=${channel}`;
    }

    return npath;
};
