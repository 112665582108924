/*
 * @Author: shiguang
 * @Date: 2023-04-27 14:06:40
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-13 21:12:27
 * @Description: 配置 axios 相关信息
 */
import qs from 'query-string';

// const getQueryEnv = () => {
//     const query = qs.parse(window.location.search) as { _env?: string };
//     return query?._env;
// };

const getRquestBaseUrlByEnv = (env: string) => {
    const prodUrl = 'https://gateway-prod.theckb.com';
    const preUrl = 'https://pre-gateway.theckb.com';
    const master = 'https://master-gateway.theckb.com';
    let returnUrl = '';
    switch (env) {
        case 'prod':
            returnUrl = prodUrl;
            break;
        case 'pre':
            returnUrl = preUrl;
            break;
        case 'test':
            returnUrl = master;
            break;
        case 'dev':
            returnUrl = master;
            break;
        default:
            returnUrl = prodUrl;
            break;
    }
    return returnUrl;
};

// 1. 可以在 url 上配置
// 2. 可以在 代码中 动态改动
// 3. 根据域名处理
export const serviceConfig = (() => {
    return {
        baseURL: (() => {
            if (
                process.env.REACT_APP_URL &&
                process.env.REACT_APP_URL !== 'undefined'
            )
                return process.env.REACT_APP_URL;
            const queryEnv = process.env.REACT_APP_ENV || 'prod';
            if (queryEnv) {
                return getRquestBaseUrlByEnv(queryEnv);
            }
            const devUrl = 'https://master-gateway.theckb.com';
            if (window.location.port) return devUrl;
            const arr = window.location.host.split('-');
            let [env] = arr;
            if (arr.length < 2) env = 'prod';
            return getRquestBaseUrlByEnv(env);
        })()
    };
})();
