/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-30 14:56:56
 * @Description: App
 */
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import ja_JP from 'antd/locale/ja_JP';
// import ko_KR from 'antd/locale/ko_KR';
// import en_GB from 'antd/locale/en_GB';
// import { Locale } from 'antd/lib/locale';
import { atom, useAtom } from 'jotai';
import { ConfigProvider, Spin } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider';
import {
    RouterProvider,
    RouterProviderProps,
    createBrowserRouter
} from 'react-router-dom';
import { useAsyncEffect, useRequest } from 'ahooks';

import queryString from 'query-string';
import { CustomerDetail, Local, Gloading } from '@/model';
import ToAppModal from '@/common/CKBLayout/modules/ToAppModal';
import { ROUTER_BASENAME } from './config/base';
import routers from './config/router';
import CKBLayout from './common/CKBLayout';
import { getLang, Lang, LocalContext } from './i18n/init';

// import '@/styles/theme/index.scss';
import './App.css';
import { checkLogin, toTheCkb } from './config/request/login';

import { CountriesEnum } from './const';
import { ACCOUNT_TYPE } from './const/enum';
import { urlAddChannel } from './utils';
console.log(ja_JP);

const createRouter = (): RouterProviderProps['router'] => {
    const newRouter = [...routers];
    if (process.env.REACT_APP_ENV !== 'prod') {
        newRouter.unshift({
            path: '/dev/login',
            title: 'dev-login',
            component: lazy(() => import('./container/dev/Login')),
            showMenu: false
        });
    }
    return createBrowserRouter(
        newRouter.map((item) => {
            const Comp = item.component;
            return {
                element: (
                    <CKBLayout isShowMenu={item.showMenu}>
                        <Suspense fallback={<div />}>
                            {Comp && <Comp />}
                        </Suspense>
                    </CKBLayout>
                ),
                path: item.path,
                errorElement: <div>err</div>
            };
        }),
        {
            basename: `${ROUTER_BASENAME}`
        }
    );
};

export const atomRequestCustomerDetail = CustomerDetail;

export const router = createRouter();
const { channel } = queryString.parse(window.location.search);
router.subscribe((data) => {
    const { location } = data;
    console.log(channel);
    // 渠道，若是该字段存在，则路由上一直带着
    if (channel) {
        const _search = queryString.stringify({
            ...queryString.parse(location.search),
            channel
        });
        console.log(location.pathname + '?' + _search);
        setTimeout(() => {
            window.history.replaceState(
                null,
                '',
                location.pathname + '?' + _search
            );
        }, 1);
    }
});
export const getRouter = () => {
    return router;
};
// const { langType } = Local;
const { gloading } = Gloading;

function App() {
    // const [locale, setLocale] = useState<Locale>();
    const [detais, requestCustomerDetail] = useAtom(atomRequestCustomerDetail);

    // const [local] = useAtom(langType);
    const [loading] = useAtom(gloading);
    // const navigate = useNavigate();
    useAsyncEffect(async () => {
        const res = await checkLogin(false);
        if (res) {
            const res = await requestCustomerDetail();
            if (res?.accountType === ACCOUNT_TYPE.SUB) {
                window.location.replace(toTheCkb());
                return;
            }
            // 除404页面外只要拿到非日本信息就跳转404
            if (!res?.isJA && !window.location.href.includes('/page404')) {
                window.location.href = urlAddChannel('/page404');
            }
        }
    }, []);

    const ThemeStyle: ThemeConfig = {
        token: {
            colorPrimary: '#FA6400',
            controlOutlineWidth: 1
        },
        components: {
            Badge: {
                boxShadow: '0',
                boxShadowSecondary: '0',
                boxShadowTertiary: '0',
                lineWidth: 0
            },
            Modal: {
                colorPrimary: '#FA6400',
                borderRadiusOuter: 8,
                borderRadius: 8
            },
            Menu: {
                // 默认的背景和颜色
                itemBg: '#fff',
                itemColor: 'rgba(86, 86, 86, .8)',
                itemSelectedColor: 'rgba(255, 80, 16, .8)',
                itemHoverBg: '#FFEBDE',
                itemHoverColor: 'rgba(255, 80, 16, .8)',
                itemBorderRadius: 4
            },
            Button: {
                borderRadius: 16,
                controlOutlineWidth: 0,
                motionEaseInOutCirc: 'cubic-dezier(0,0,0,0)'
            },
            Wave: {
                motionEaseInOutCirc: 'cubic-dezier(0,0,0,0)',
                colorPrimary: '#fff'
            },
            Input: {
                controlOutlineWidth: 0
            },
            InputNumber: {
                controlWidth: 200
            },
            Table: {
                headerSplitColor: '#F9F9F9',
                headerColor: '#565656',
                rowSelectedBg: '#F9F9F9',
                rowSelectedHoverBg: '#F9F9F9'
            },
            Form: {
                itemMarginBottom: 12
            }
        }
    };

    return (
        <div id="J_B2B" className="App min-w-[1200px]">
            <LocalContext.Provider value={Lang.ja_JP}>
                <ConfigProvider locale={ja_JP} theme={ThemeStyle}>
                    <Spin spinning={!!loading}>
                        <RouterProvider
                            router={router}
                            fallbackElement={<div>fallbackElement</div>}
                        />
                        <div className="text-center">
                            <small>
                                ©︎ 2022 THE CKB All Rights Reserved.
                                <a
                                    href="https://beian.miit.gov.cn"
                                    // style="margin-left:10px;"
                                    className="ml-[10px]"
                                >
                                    浙ICP备16032890号-11
                                </a>
                            </small>
                        </div>
                    </Spin>
                </ConfigProvider>
            </LocalContext.Provider>
            <ToAppModal />
        </div>
    );
}

export default App;
