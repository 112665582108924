/*
 * @Date: 2023-10-10 14:22:28
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-06 15:33:26
 * @FilePath: /ckb-mall-web/src/model/CustomerDetail.ts
 * @Description:
 * @Author: lihwang
 */
import { atom } from 'jotai';
import { request } from '@/config/request';
import {
    CustomerDetailRespDTO,
    CustomerMembershipResDTO
} from '@/service/customer';
import {
    getCountryByNavigatorLang,
    Site,
    simpleSite,
    getSite,
    CountriesEnum
} from '@/const';

interface CustomerMembershipResDTO2 extends CustomerMembershipResDTO {
    templateLevel?: number;
}
interface CustomerDetailRespDTO2 extends CustomerDetailRespDTO {
    membership?: CustomerMembershipResDTO2;
    site?: string;
    isKO?: boolean;
    isJA?: boolean;
    isEN?: boolean;
}
export const atomCustomerDetail = atom<CustomerDetailRespDTO2 | undefined>(
    undefined
);

/** 获取用户信息 */
const atomRequestCustomerDetail = atom(
    (get) => get(atomCustomerDetail),
    async (_get, set) => {
        const defaultStationCode = getCountryByNavigatorLang();
        try {
            const response =
                await request.customer.getCustomerDetails.getCustomerDetails();
            localStorage.setItem(
                'stationCode',
                response.data?.stationCode as string
            );
            const res = {
                ...response.data,
                site: getSite(response.data?.stationCode ?? ''),
                isJA: response.data?.stationCode === Site.JP,
                isKO: response.data?.stationCode === Site.KR,
                isEN: response.data?.stationCode === Site.UK
            };
            set(atomCustomerDetail, res);
            return res;
        } catch {
            localStorage.setItem('stationCode', defaultStationCode);
        }
    }
);

export default atomRequestCustomerDetail;
