/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddProductFavoriteReqDTO */
export interface AddProductFavoriteReqDTO {
    /**
     * 商品类型，0-直采商品，1-代采商品
     * @format int32
     */
    favoriteProductType?: number;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; */
    platformType?: string;
    /** 商品编码 */
    productCode?: string;
    /** 商品主图 */
    productMainImg?: string;
    /** 商品价格 */
    productSellPrice?: number;
    /** 商品标题 */
    productTitle?: string;
}

/** BizResponse */
export interface BizResponse {
    code?: string;
    data?: object;
    msg?: string;
    success?: boolean;
}

/** CancelProductFavoriteReqDTO */
export interface CancelProductFavoriteReqDTO {
    /** 商品编码 */
    productCode?: string;
}

/** CheckSyncReqDTO */
export interface CheckSyncReqDTO {
    /** 商品编码信息 */
    productCode?: string;
}

/** EasyProductManageResp */
export interface EasyProductManageResp {
    basePrice?: number;
    /** @format int64 */
    calculateWeight?: number;
    /**
     * 类目重量,单位g
     * @format int64
     */
    cateWeight?: number;
    /**
     * 创建日期
     * @format date-time
     */
    createTime?: string;
    /** 出货条件 */
    deliveryCondition?: string;
    /** @format double */
    height?: number;
    /** 完整层级类目代码 */
    intactCateCode?: string;
    /** 完整层级类目名称(后台类目) */
    intactCateName?: string;
    internationalFreight?: number;
    /** @format double */
    length?: number;
    /** 材质信息日文 */
    materialInfoJa?: string;
    /** 材质信息中文 */
    materialInfoZh?: string;
    /** 注意事项 */
    notes?: string;
    /** 原标题 */
    originalProductTitle?: string;
    /** 原日文标题 */
    originalProductTitleJa?: string;
    platformType?: string;
    /** 溢价率 */
    premiumRate?: number;
    /** @format int32 */
    productBuyMininum?: number;
    productCateCode?: string;
    /** 商品编码 */
    productCode?: string;
    /** 描述图片（商品详情） */
    productDescImgs?: string;
    productDescription?: string;
    productDescriptionJa?: string;
    productDetailSniffUrl?: string;
    /** 直采商详链接 */
    productDetailUrl?: string;
    /** 中文品名 */
    productItemCategoryZh?: string;
    /** item缩略图（大） */
    productItemImgs?: string;
    /** item缩略图（小） */
    productItemImgsS?: string;
    /** 商品主图 */
    productMainImg?: string;
    /** @format int32 */
    productNo?: number;
    productPriceLadder?: string;
    productPropImgs?: string;
    productProps?: string;
    productPropsJa?: string;
    productPropsJaList?: string;
    productPropsList?: string;
    productSellHighestPrice?: number;
    productSellLowestPrice?: number;
    productSellPrice?: number;
    /** 商品价格范围 */
    productSellPriceRange?: string;
    /** @format int32 */
    productSellQuantity?: number;
    /** @format int32 */
    productStockQuantity?: number;
    /** 商品标题 */
    productTitle?: string;
    /** @format int32 */
    productType?: number;
    productVideo?: string;
    productVideoImg?: string;
    /** 销售价 */
    sellPrice?: number;
    /** 销售价范围 */
    sellPriceRange?: number;
    /**
     * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
     * @format int32
     */
    status?: number;
    /** 系统商品标题 */
    systemProductTitle?: string;
    thirdProductCateCode?: string;
    /** 三方平台商详链接 */
    thirdProductDetailUrl?: string;
    thirdSize?: string;
    /** @format int64 */
    thirdWeight?: number;
    /** 类目运输属性 */
    transportAttr?: string;
    transportAttrEmpty?: boolean;
    /**
     * 更新日期
     * @format date-time
     */
    updateTime?: string;
    /**
     * 重，单位kg
     * @format int64
     */
    weight?: number;
    weightEmpty?: boolean;
    /** @format double */
    width?: number;
}

/** EasyProductResp */
export interface EasyProductResp {
    /** 基准价 */
    basePrice?: number;
    /** 商品阶梯价 */
    base_price_ladder?: string;
    /**
     * 计算重量
     * @format int64
     */
    calculateWeight?: number;
    /**
     * 类目重量
     * @format int64
     */
    cateWeight?: number;
    /**
     * 创建日期
     * @format date-time
     */
    createTime?: string;
    /** 出货条件 */
    deliveryCondition?: string;
    /**
     * 高，单位cm
     * @format double
     */
    height?: number;
    /** 完整层级类目代码 */
    intactCateCode?: string;
    /** 完整层级类目名称 */
    intactCateName?: string;
    /** 国际运费 */
    internationalFreight?: number;
    /**
     * 长，单位cm
     * @format double
     */
    length?: number;
    /** 材质信息日文 */
    materialInfoJa?: string;
    /** 材质信息中文 */
    materialInfoZh?: string;
    /** 注意事项 */
    notes?: string;
    /** 原材质信息日文 */
    originalMaterialInfoJa?: string;
    /** 原中文品名 */
    originalProductItemCategoryZh?: string;
    /** 原标题 */
    originalProductTitle?: string;
    /** 原日文标题 */
    originalProductTitleJa?: string;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
    platformType?: string;
    /** 溢价率 */
    premiumRate?: number;
    /**
     * 最小购买数
     * @format int32
     */
    productBuyMininum?: number;
    /** 类目编码 */
    productCateCode?: string;
    /** 平台商品SPU */
    productCode?: string;
    /**
     * 商品报关ID
     * @format int64
     */
    productCustomsId?: number;
    /** 描述图片（商品详情） */
    productDescImgs?: string;
    /** 描述图片（商品详情） */
    productDescImgsList?: string[];
    /** 商品描述 */
    productDescription?: string;
    /** 日文商品描述 */
    productDescriptionJa?: string;
    /** sniff商品详情链接 */
    productDetailSniffUrl?: string;
    /** 直采商详链接 */
    productDetailUrl?: string;
    /** 中文品名 */
    productItemCategoryZh?: string;
    /** item缩略图（大） */
    productItemImgs?: string;
    /** item缩略图（大） */
    productItemImgsList?: ProductImageUrlDTO[];
    /** item缩略图（小） */
    productItemImgsS?: string;
    productKeywords?: string;
    /** 主图 */
    productMainImg?: string;
    /** 商品番号 */
    productNo?: string;
    /** 商品阶梯价 */
    productPriceLadder?: string;
    /** 价格方案 */
    productPriceLadderList?: ProductPriceLadderResp[];
    /** 属性图片 */
    productPropImgs?: string;
    /** 商品详情属性 */
    productProps?: string;
    /** 商品详情属性（日文） */
    productPropsJa?: string;
    /** 商品sku属性日文列表 */
    productPropsJaList?: string;
    /** 商品sku属性列表 */
    productPropsList?: string;
    /** 商品价格 */
    productSellPrice?: number;
    /** 商品价格范围 */
    productSellPriceRange?: string;
    /**
     * 销量
     * @format int32
     */
    productSellQuantity?: number;
    /**
     * 库存
     * @format int32
     */
    productStockQuantity?: number;
    /** 商品名称 */
    productTitle?: string;
    /**
     * 商品类型: 0-线上，1-线下
     * @format int32
     */
    productType?: number;
    /** 视频 */
    productVideo?: string;
    /** 视频海报图 */
    productVideoImg?: string;
    /** 销售价 */
    sellPrice?: number;
    /** 商品阶梯价 */
    sell_price_ladder?: string;
    /** sku管理 */
    skuList?: EasySkuResp[];
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
     * @format int32
     */
    status?: number;
    /** 系统商品名称 */
    systemProductTitle?: string;
    /** 第三方商品类目编码 */
    thirdProductCateCode?: string;
    /** 三方平台商详链接 */
    thirdProductDetailUrl?: string;
    /** 三方尺寸 */
    thirdSize?: string;
    /**
     * 三方重量
     * @format int64
     */
    thirdWeight?: number;
    /** 类目运输属性 */
    transportAttr?: string;
    /**
     * 更新日期
     * @format date-time
     */
    updateTime?: string;
    /**
     * 重，单位kg
     * @format int64
     */
    weight?: number;
    /**
     * 宽，单位cm
     * @format double
     */
    width?: number;
}

/** EasySkuResp */
export interface EasySkuResp {
    /** 基准价 */
    basePrice?: number;
    /**
     * 计算重量
     * @format int64
     */
    calculateWeight?: number;
    /**
     * 创建日期
     * @format date-time
     */
    createTime?: string;
    /**
     * 可售库存
     * @format int32
     */
    enbleStockQuantity?: number;
    /**
     * 高，单位cm
     * @format double
     */
    height?: number;
    /** 国际运费 */
    internationalFreight?: number;
    /**
     * 长，单位cm
     * @format double
     */
    length?: number;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
    platformType?: string;
    /** 溢价率 */
    premiumRate?: number;
    /**
     * 最小购买数
     * @format int32
     */
    productBuyMininum?: number;
    /** 商品编码 */
    productCode?: string;
    /** 属性标识 */
    productProperties?: string;
    /** 属性名称 */
    productPropertiesName?: string;
    /** 属性名称(日文) */
    productPropertiesNameJa?: string;
    productPropertiesNameParse?: string;
    /** 销售单价(参考价) */
    productSellPrice?: number;
    /**
     * 销售量
     * @format int32
     */
    productSellQuantity?: number;
    /** 商品sku */
    productSku?: string;
    /** 商品sku图 */
    productSkuImg?: string;
    /**
     * 库存
     * @format int32
     */
    productStockQuantity?: number;
    /** 销售价 */
    sellPrice?: number;
    /**
     * 商品装填
     * @format int32
     */
    status?: number;
    /**
     * 仓库库存
     * @format int32
     */
    stockQuantity?: number;
    /** 三方尺寸 */
    thirdSize?: string;
    /**
     * 三方重量
     * @format int64
     */
    thirdWeight?: number;
    /** 运输属性 */
    transportAttr?: string;
    /** 运输属性code */
    transportAttrCode?: string;
    /**
     * 更新日期
     * @format date-time
     */
    updateTime?: string;
    /**
     * 重，单位kg
     * @format int64
     */
    weight?: number;
    /**
     * 宽，单位cm
     * @format double
     */
    width?: number;
}

/** EsProductEditReqDTO */
export interface EsProductEditReqDTO {
    productCode?: string;
    productMainImg?: string;
    productSellHighestPrice?: number;
    productSellLowestPrice?: number;
    productTitle?: string;
    /** @format int32 */
    status?: number;
    systemProductTitle?: string;
}

/** ExportProductReqDTO */
export interface ExportProductReqDTO {
    /** 类目编码(三级) */
    productCateCodeList?: string[];
    /** 商品code */
    productCode?: string;
    /** 商品code */
    productCodeList?: string[];
    /** 商品标题 */
    productTitle?: string;
    /**
     * 0-线上，1-线下
     * @format int32
     */
    productType?: number;
    /** SKU列表 */
    skuList?: string[];
    /** 站点 */
    stationCode?: string;
    /**
     * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
     * @format int32
     */
    status?: number;
    /** 三方平台商详链接 */
    thirdProductDetailUrl?: string;
    /** 重量是否为空 */
    weightIsNull?: boolean;
}

/** ImportProductReqDTO */
export interface ImportProductReqDTO {
    /**
     * 商品类型 0-线上，1-线下
     * @format int32
     */
    productType?: number;
    /**
     * 导入类型- 1：商品 2：sku
     * @format int32
     */
    type?: number;
    /** url */
    url?: string;
}

/** OrderItem */
export interface OrderItem {
    asc?: boolean;
    column?: string;
}

/** ProductCategoryFrontendAllParentNodeDTO */
export interface ProductCategoryFrontendAllParentNodeDTO {
    cateIcon?: string;
    cateNameEn?: string;
    cateNameJp?: string;
    cateNameKr?: string;
    cateNameZh?: string;
    /** @format int32 */
    defaultFlag?: number;
    /** @format int32 */
    level?: number;
    /** @format int64 */
    parentId?: number;
    /** @format int64 */
    productCategoryFrontendId?: number;
    stationCode?: string;
    /** @format int32 */
    status?: number;
}

/** ProductCategoryFrontendInsertReqDTO */
export interface ProductCategoryFrontendInsertReqDTO {
    cateCodeList?: string[];
    cateIcon?: string;
    /** 类目名称（英文） */
    cateNameEn?: string;
    /** 类目名称（日文） */
    cateNameJp?: string;
    /** 类目名称（韩文） */
    cateNameKr?: string;
    cateNameZh?: string;
    /** @format int32 */
    defaultFlag?: number;
    /** @format int32 */
    level?: number;
    /** @format int64 */
    parentId?: number;
    /** 站点code */
    stationCode?: string;
}

/** ProductCategoryFrontendMapGroupRespDTO */
export interface ProductCategoryFrontendMapGroupRespDTO {
    cateCodeList?: ProductCategorySingleRespDTO[];
    /** @format int64 */
    productCategoryFrontendId?: number;
    /**
     * 前台类目ID
     * @format int64
     */
    productCategoryFrontendReqId?: number;
}

/** ProductCategoryFrontendMapReqDTO */
export interface ProductCategoryFrontendMapReqDTO {
    cateCode?: string;
    cateCodeList?: string[];
    /**
     * 前台商品类目ID
     * @format int64
     */
    productCategoryFrontendId?: number;
    /** 前台商品类目ID集合 */
    productCategoryFrontendIds?: number[];
    /** @format int32 */
    productCategoryFrontendLevel?: number;
    stationCode?: string;
}

/** ProductCategoryFrontendRespDTO */
export interface ProductCategoryFrontendRespDTO {
    cateIcon?: string;
    cateNameEn?: string;
    cateNameJp?: string;
    cateNameKr?: string;
    cateNameZh?: string;
    channels?: number[];
    children?: ProductCategoryFrontendRespDTO[];
    /** @format date-time */
    createTime?: string;
    /** @format int32 */
    defaultFlag?: number;
    /** @format int32 */
    fastCategoryFlag?: number;
    /** @format int32 */
    level?: number;
    /** @format int64 */
    parentId?: number;
    /** @format int64 */
    productCategoryFrontendId?: number;
    /** @format int32 */
    sort?: number;
    /** @format int32 */
    status?: number;
    /** @format date-time */
    updateTime?: string;
}

/** ProductCategoryFrontendShortRespDTO */
export interface ProductCategoryFrontendShortRespDTO {
    cateIcon?: string;
    cateNameEn?: string;
    cateNameJp?: string;
    cateNameKr?: string;
    cateNameZh?: string;
    children?: ProductCategoryFrontendShortRespDTO[];
    /** @format int32 */
    level?: number;
    /** @format int64 */
    parentId?: number;
    /** @format int64 */
    productCategoryFrontendId?: number;
    stationCode?: string;
}

/** ProductCategoryFrontendUpdateReqDTO */
export interface ProductCategoryFrontendUpdateReqDTO {
    cateCodeList?: string[];
    cateIcon?: string;
    /** 类目名称（英文） */
    cateNameEn?: string;
    /** 类目名称（日文） */
    cateNameJp?: string;
    /** 类目名称（韩文） */
    cateNameKr?: string;
    cateNameZh?: string;
    /** @format int32 */
    defaultFlag?: number;
    /** @format int64 */
    productCategoryFrontendId?: number;
    /** 站点code */
    stationCode?: string;
}

/** ProductCategorySingleRespDTO */
export interface ProductCategorySingleRespDTO {
    cateCodeList?: string[];
    /** @format int32 */
    level?: number;
}

/** ProductDetailReqDTO */
export interface ProductDetailReqDTO {
    productCode: string;
    stationCode?: string;
}

/** ProductDetailRespDTO */
export interface ProductDetailRespDTO {
    /**
     * 是否收藏;1-已收藏;0-未收藏
     * @format int32
     */
    favoriteFlag?: number;
    /**
     * 一元购标识， 0 否 1 是
     * @format int32
     */
    oneBuyFlag?: number;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
    platformType?: string;
    /**
     * 最小购买数/起拍数
     * @format int32
     */
    productBuyMininum?: number;
    /** 商品编码 */
    productCode?: string;
    /** 描述图片 */
    productDescImgs?: string[];
    /** 商品描述 */
    productDescription?: string;
    /** 日文商品描述 */
    productDescriptionJa?: string;
    /** sniff商品详情链接url */
    productDetailSniffUrl?: string;
    /** 直采商详链接url */
    productDetailUrl?: string;
    productExchangeRate?: number;
    /** item缩略图（大） */
    productItemImgs?: ProductImageUrlDTO[];
    /** item缩略图（小） */
    productItemImgsS?: ProductImageUrlDTO[];
    /** 主图 */
    productMainImg?: string;
    /** 商品阶梯价 */
    productPriceLadderList?: ProductPriceLadderDTO[];
    /** 商品属性组-日文 */
    productPropGroupJaList?: ProductPropGroupDTO[];
    /** 商品属性组 */
    productPropGroupList?: ProductPropGroupDTO[];
    /** 商品详情属性 */
    productProps?: string;
    /** 商品sku属性列表 */
    productPropsList?: string;
    /** 商品最高价格 */
    productSellHighestPrice?: number;
    /** 商品最高价格-日元 */
    productSellHighestPriceJa?: number;
    /** 商品最低价格 */
    productSellLowestPrice?: number;
    /** 商品最低价格-日元 */
    productSellLowestPriceJa?: number;
    /**
     * 销量
     * @format int32
     */
    productSellQuantity?: number;
    /**
     * 库存
     * @format int32
     */
    productStockQuantity?: number;
    /** 商品名称 */
    productTitle?: string;
    /**
     * 商品类型， 0 线上 1 线下
     * @format int32
     */
    productType?: number;
    /** 商品视频介绍 */
    productVideo?: string;
    /** sku列表 */
    skuList?: SkuDetailRespDTO[];
    /** 站点 */
    stationCode?: string;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
     * @format int32
     */
    status?: number;
    /** 三方平台商详链接url */
    thirdProductDetailUrl?: string;
}

/** ProductErrorRespDTO */
export interface ProductErrorRespDTO {
    /** 错误码 */
    errorCode?: string;
    /** 失败原因 */
    msg?: string;
    /** 商品编码 */
    productCode?: string;
}

/** ProductFavoritePageReqDTO */
export interface ProductFavoritePageReqDTO {
    /**
     * 商品类型，0-直采商品，1-代采商品
     * @format int32
     */
    favoriteProductType?: number;
    /** 按照时间排序 asc 由小到大，desc 由大到小 */
    orderByType?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /** @format int32 */
    startIndex?: number;
}

/** ProductFavoriteRespDTO */
export interface ProductFavoriteRespDTO {
    /**
     * 可用库存
     * @format int32
     */
    availableQuantity?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /**
     * 客户ID
     * @format int64
     */
    customerId?: number;
    /**
     * 商品类型，0-直采商品，1-代采商品
     * @format int32
     */
    favoriteProductType?: number;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; */
    platformType?: string;
    /** 商品类目编码 */
    productCateCode?: string;
    /** 商品编码 */
    productCode?: string;
    /** 商品主图 */
    productMainImg?: string;
    /** 商品价格 */
    productSellPrice?: number;
    /** 商品标题 */
    productTitle?: string;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
     * @format int32
     */
    status?: number;
}

/** ProductImageUrlDTO */
export interface ProductImageUrlDTO {
    url?: string;
}

/** ProductKeyWordSearchReqDTO */
export interface ProductKeyWordSearchReqDTO {
    /** @format int64 */
    customerId?: number;
    /** 降序 */
    descendOrder?: boolean;
    /** 结束价格 */
    endPrice?: number;
    /** 搜索关键字 */
    keyword?: string;
    /** 只看直采商品, 是：true， 否：false */
    onlyDirctSale?: boolean;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 前端类目编码
     * @format int64
     */
    productCategoryFrontendId?: number;
    /**
     * 排序字段:0 综合 1 价格(默认) 2 时间
     * @format int32
     */
    sortType?: number;
    /** @format int32 */
    startIndex?: number;
    /** 开始价格 */
    startPrice?: number;
    stationCode?: string;
}

/** ProductKeywordRespDTO */
export interface ProductKeywordRespDTO {
    /**
     * 可用库存
     * @format int32
     */
    availableQuantity?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /**
     * 是否收藏,1 是, 否：0
     * @format int32
     */
    favoriteFlag?: number;
    /** 完整层级类目代码 */
    intactCateCode?: string;
    /** 完整层级类目名称 */
    intactCateName?: string;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; XX:线下 */
    platformType?: string;
    /** 类目 */
    productCateCode?: string;
    /** 商品编码 */
    productCode?: string;
    /** 产品主图 */
    productMainImg?: string;
    /** 商品番号 */
    productNo?: string;
    /**
     * 采购类型大写,代采 1, 直采：0
     * @format int32
     */
    productPurchaseType?: number;
    /** 日币销售价格 */
    productSellPriceJa?: number;
    /** 商品价格范围 */
    productSellPriceRange?: string;
    /** 商品标题 */
    productTitle?: string;
    /**
     * 三方平台类型：1688、淘宝、sniff
     * @format int32
     */
    productType?: number;
    /** 销售价 */
    sellPrice?: number;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
     * @format int32
     */
    status?: number;
    /** 系统商品标题 */
    systemProductTitle?: string;
}

/** ProductManageReqDTO */
export interface ProductManageReqDTO {
    /** 商品编码信息 */
    productCodes?: string[];
    /** 站点国家 */
    stationCode?: string;
}

/** ProductManageRespDTO */
export interface ProductManageRespDTO {
    /** 商品最高基准价格 */
    baseHighestPrice?: number;
    /** 商品最低基准价格 */
    baseLowestPrice?: number;
    basePrice?: number;
    /** @format int64 */
    calculateWeight?: number;
    /**
     * 类目重量,单位g
     * @format int64
     */
    cateWeight?: number;
    /**
     * 创建日期
     * @format date-time
     */
    createTime?: string;
    /** 出货条件 */
    deliveryCondition?: string;
    /** @format double */
    height?: number;
    /** 完整层级类目代码 */
    intactCateCode?: string;
    /** 完整层级类目名称(后台类目) */
    intactCateName?: string;
    internationalFreight?: number;
    /** @format double */
    length?: number;
    /** 材质信息日文 */
    materialInfoJa?: string;
    /** 材质信息中文 */
    materialInfoZh?: string;
    /** 注意事项 */
    notes?: string;
    /** 原日文标题 */
    originalProductTitleJa?: string;
    platformType?: string;
    /** 溢价率 */
    premiumRate?: number;
    /** @format int32 */
    productBuyMininum?: number;
    productCateCode?: string;
    /** 商品编码 */
    productCode?: string;
    /** 描述图片（商品详情） */
    productDescImgs?: string;
    productDescription?: string;
    productDescriptionJa?: string;
    productDetailSniffUrl?: string;
    /** 直采商详链接 */
    productDetailUrl?: string;
    /** 中文品名 */
    productItemCategoryZh?: string;
    /** item缩略图（大） */
    productItemImgs?: string;
    /** item缩略图（小） */
    productItemImgsS?: string;
    /** 商品主图 */
    productMainImg?: string;
    /** @format int32 */
    productNo?: number;
    /** 商品阶梯价格列表 */
    productPriceLadder?: string;
    /** 商品阶梯价格 */
    productPriceLadderList?: ProductPriceLadderDTO[];
    productPropImgs?: string;
    productProps?: string;
    productPropsJa?: string;
    productPropsJaList?: string;
    productPropsList?: string;
    /** 商品最低销售价格 */
    productSellLowestPrice?: number;
    /** 商品最低销售价格(日元价格) */
    productSellLowestPriceJa?: number;
    productSellPrice?: number;
    /** 商品价格范围 */
    productSellPriceRange?: string;
    /** @format int32 */
    productSellQuantity?: number;
    /** @format int32 */
    productStockQuantity?: number;
    /** 商品标题 */
    productTitle?: string;
    /** @format int32 */
    productType?: number;
    productVideo?: string;
    productVideoImg?: string;
    /** 销售价 */
    sellPrice?: number;
    /** 销售价范围 */
    sellPriceRange?: number;
    /**
     * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
     * @format int32
     */
    status?: number;
    /** 系统商品标题 */
    systemProductTitle?: string;
    thirdProductCateCode?: string;
    /** 三方平台商详链接 */
    thirdProductDetailUrl?: string;
    thirdSize?: string;
    /** @format int64 */
    thirdWeight?: number;
    /** 类目运输属性 */
    transportAttr?: string;
    transportAttrEmpty?: boolean;
    /**
     * 更新日期
     * @format date-time
     */
    updateTime?: string;
    /**
     * 重，单位kg
     * @format int64
     */
    weight?: number;
    weightEmpty?: boolean;
    /** @format double */
    width?: number;
}

/** ProductPriceLadderDTO */
export interface ProductPriceLadderDTO {
    /** app价格 */
    appPrice?: number;
    /** 基准价 */
    basePrice?: number;
    /**
     * 阶梯数量
     * @format int32
     */
    count?: number;
    /** 国际运费 */
    internationalFreight?: number;
    /** 溢价率 */
    premiumRate?: number;
    /** pc价格 */
    price?: number;
    /** 销售价格 */
    sellPrice?: number;
}

/** ProductPriceLadderResp */
export interface ProductPriceLadderResp {
    /** app价格 */
    appPrice?: number;
    /** 基准价(原价格 + 国际运费) */
    basePrice?: number;
    /**
     * 阶梯数量
     * @format int32
     */
    count?: number;
    /** 阶梯数量范围 */
    countRange?: string;
    /** 国际运费 */
    internationalFreight?: number;
    /** 原始价格 */
    originPrice?: number;
    /** 溢价率 */
    premiumRate?: number;
    /** 原价格(销售单价(参考价)) */
    price?: number;
    /** 销售单价(参考价) */
    productSellPrice?: number;
    /** 销售价格(基准价 * 溢价率) */
    sellPrice?: number;
}

/** ProductPropDTO */
export interface ProductPropDTO {
    /** 属性id */
    id?: string;
    /** 属性编码 */
    propKey?: string;
    /** 属性值 */
    propValue?: string;
    /** 属性信息 */
    props?: ProductPropDTO[];
    /** @format int64 */
    sort?: number;
    /** 属性类型 */
    type?: string;
    /** 图片url */
    url?: string;
}

/** ProductPropGroupDTO */
export interface ProductPropGroupDTO {
    /** 属性id */
    id?: string;
    /** 属性信息 */
    props?: ProductPropDTO[];
    /** 属性类型 */
    type?: string;
}

/** ProductQueryReqDTO */
export interface ProductQueryReqDTO {
    /** 降序 */
    descendOrder?: boolean;
    /** 排除已删除 */
    excludeDeleted?: boolean;
    /** 排除商品code */
    noProductCode?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /** 类目编码(三级) */
    productCateCodeList?: string[];
    /** 商品code */
    productCodeList?: string[];
    /** 商品番号 */
    productNo?: string;
    /** 商品标题 */
    productTitle?: string;
    /**
     * 0-线上，1-线下
     * @format int32
     */
    productType?: number;
    /** 最低销售价格截止价格 */
    queryEndProductSellPrice?: number;
    /** 最低销售价格起始价格 */
    queryStartProductSellPrice?: number;
    searchField?: string[];
    /** SKU列表 */
    skuList?: string[];
    /** 排序字段; (默认)更新时间: update_time; */
    sortType?: string;
    /** @format int32 */
    startIndex?: number;
    /** 站点 */
    stationCode?: string;
    /**
     * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
     * @format int32
     */
    status?: number;
    /** 三方平台商详链接 */
    thirdProductDetailUrl?: string;
    /** 重量是否为空 */
    weightIsNull?: boolean;
}

/** ProductStockOperateReqDTO */
export interface ProductStockOperateReqDTO {
    bizId?: string;
    /** @format int32 */
    operateType?: number;
    productSku?: string;
    stationCode?: string;
    /** @format int32 */
    stockQuantity?: number;
}

/** SearchImageReqDTO */
export interface SearchImageReqDTO {
    /** 图片ID */
    imageId?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /** @format int32 */
    startIndex?: number;
}

/** SearchImageRespDTO */
export interface SearchImageRespDTO {
    /**
     * 可用库存
     * @format int32
     */
    availableQuantity?: number;
    /**
     * 是否收藏,1 是, 否：0
     * @format int32
     */
    favoriteFlag?: number;
    /** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城;  */
    platformType?: string;
    /** 商品编码 */
    productCode?: string;
    /** 商品主图 */
    productMainImg?: string;
    /**
     * 采购类型大写,代采 1, 直采：0
     * @format int32
     */
    productPurchaseType?: number;
    /** 销售价格(日元) */
    productSellPriceJa?: number;
    /** 商品标题 */
    productTitle?: string;
    /** 销售价 */
    sellPrice?: number;
    /** 站点编码 */
    stationCode?: string;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
     * @format int32
     */
    status?: number;
}

/** SearchImageUploadReqDTO */
export interface SearchImageUploadReqDTO {
    /** imageBase64Content编码 */
    imageBase64Content?: string;
    imageUrl?: string;
}

/** SkuDetailRespDTO */
export interface SkuDetailRespDTO {
    /**
     * 可用库存
     * @format int32
     */
    availableQty?: number;
    /** 溢价率 */
    premiumRate?: number;
    /** 商品SPU编码 */
    productCode?: string;
    /** 属性标识 */
    productProperties?: string;
    /** 属性名称,格式: 0:1:颜色:粉色;1:5:尺码:XXXL */
    productPropertiesName?: string;
    /** 属性名称-日文 */
    productPropertiesNameJa?: string;
    /** 属性标识排序 */
    productPropertiesSort?: string;
    /** 销售单价 */
    productSellPrice?: number;
    /**
     * 销售量
     * @format int32
     */
    productSellQuantity?: number;
    /** 平台商品SKU */
    productSku?: string;
    /** sku对应图片 */
    productSkuImg?: string;
    /**
     * 库存
     * @format int32
     */
    productStockQuantity?: number;
    /** 销售价 */
    sellPrice?: number;
}

/** TestReq */
export interface TestReq {
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /** @format int32 */
    startIndex?: number;
}

/** TestResp */
export type TestResp = object;

/** BizResponse«EasyProductResp» */
export interface BizResponseEasyProductResp {
    code?: string;
    data?: EasyProductResp;
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendMapGroupRespDTO»» */
export interface BizResponseListProductCategoryFrontendMapGroupRespDTO {
    code?: string;
    data?: ProductCategoryFrontendMapGroupRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendRespDTO»» */
export interface BizResponseListProductCategoryFrontendRespDTO {
    code?: string;
    data?: ProductCategoryFrontendRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendShortRespDTO»» */
export interface BizResponseListProductCategoryFrontendShortRespDTO {
    code?: string;
    data?: ProductCategoryFrontendShortRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductErrorRespDTO»» */
export interface BizResponseListProductErrorRespDTO {
    code?: string;
    data?: ProductErrorRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductManageRespDTO»» */
export interface BizResponseListProductManageRespDTO {
    code?: string;
    data?: ProductManageRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«string»» */
export interface BizResponseListString {
    code?: string;
    data?: string[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«PageResult«ProductFavoriteRespDTO»» */
export interface BizResponsePageResultProductFavoriteRespDTO {
    code?: string;
    data?: PageResultProductFavoriteRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«PageResult«ProductKeywordRespDTO»» */
export interface BizResponsePageResultProductKeywordRespDTO {
    code?: string;
    data?: PageResultProductKeywordRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«PageResult«SearchImageRespDTO»» */
export interface BizResponsePageResultSearchImageRespDTO {
    code?: string;
    data?: PageResultSearchImageRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«EasyProductManageResp»» */
export interface BizResponsePageEasyProductManageResp {
    code?: string;
    data?: PageEasyProductManageResp;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«TestResp»» */
export interface BizResponsePageTestResp {
    code?: string;
    data?: PageTestResp;
    msg?: string;
    success?: boolean;
}

/** BizResponse«ProductDetailRespDTO» */
export interface BizResponseProductDetailRespDTO {
    code?: string;
    data?: ProductDetailRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
    code?: string;
    data?: boolean;
    msg?: string;
    success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
    code?: string;
    /** @format int64 */
    data?: number;
    msg?: string;
    success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
    code?: string;
    data?: object;
    msg?: string;
    success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
    code?: string;
    data?: string;
    msg?: string;
    success?: boolean;
}

/** PageResult«ProductFavoriteRespDTO» */
export interface PageResultProductFavoriteRespDTO {
    /** @format int64 */
    current?: number;
    records?: ProductFavoriteRespDTO[];
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
    /** @format int64 */
    totalPage?: number;
}

/** PageResult«ProductKeywordRespDTO» */
export interface PageResultProductKeywordRespDTO {
    /** @format int64 */
    current?: number;
    records?: ProductKeywordRespDTO[];
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
    /** @format int64 */
    totalPage?: number;
}

/** PageResult«SearchImageRespDTO» */
export interface PageResultSearchImageRespDTO {
    /** @format int64 */
    current?: number;
    records?: SearchImageRespDTO[];
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
    /** @format int64 */
    totalPage?: number;
}

/** Page«EasyProductManageResp» */
export interface PageEasyProductManageResp {
    /** @format int32 */
    limit?: number;
    list?: EasyProductManageResp[];
    /** @format int32 */
    offset?: number;
    /** @format int64 */
    totalCount?: number;
    /** @format int64 */
    totalPage?: number;
}

/** Page«TestResp» */
export interface PageTestResp {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: TestResp[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/easy/goods'
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path
            })
            .then((response) => response.data);
    };
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/easy/goods
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    product = {
        /**
         * No description
         *
         * @tags 商品-客户端-搜索
         * @name SearchImage
         * @summary H5图搜接口
         * @request POST:/product/search/image
         */
        searchImage: (searchImageReq: SearchImageReqDTO, params: RequestParams = {}) =>
            this.request<BizResponsePageResultSearchImageRespDTO, any>({
                path: `/product/search/image`,
                method: 'POST',
                body: searchImageReq,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 商品-客户端-搜索
         * @name SearchImageUpload
         * @summary H5图搜上传接口
         * @request POST:/product/search/image/upload
         */
        searchImageUpload: (searchImageUploadReq: SearchImageUploadReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseString, any>({
                path: `/product/search/image/upload`,
                method: 'POST',
                body: searchImageUploadReq,
                type: ContentType.Json,
                ...params
            })
    };
}
