import qs from 'qs';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ENUM_PAGE, ENUM_SYSTEM_SOURCE, PageConfig } from '@/const/enum';
import { MAP_SYSTEM_SOURCE } from '@/const/map';
import { ROUTER_BASENAME } from '@/config/base';
import { getRouter } from '@/App';
import { toH5TheCkb, toTheCkb } from '@/config/request/login';
import { GetKeyByMap, GetValueByMap } from './type';
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault('Asia/Shanghai');
// export const getEnv = () => {
//     let _env: 'local' | 'prod' | 'master' | 'test01' | 'test02' | 'test03' =
//         'prod';
//     const [env, host] = window.location.host.split('-');
//     if (window.location.port) {
//         _env = 'local';
//     } else if (host) {
//         _env = env as typeof _env;
//     }
//     return _env as typeof _env;
// };

interface JumpPageDefaultOptions {
    /** 其他系统 */
    isOtherSystem?: boolean;
    /** 是否新开页面 */
    isNewPage?: boolean;
    /** navigate replace */
    replace?: boolean;
}

// 如果当前url上携带了channel，则跳转也携带上这个channel,返回一个url用来给window.open使用
// 如果url已经携带了其他参数，则通过&拼接，否则通过?拼接
export const urlAddChannel = (url: string) => {
    const channel = getQueryStringValueByName('channel');
    if (channel) {
        if (url.includes('?')) {
            return `${url}&channel=${channel}`;
        }
        return `${url}?channel=${channel}`;
    }
    return url;
};

/**
 * 跳转页面
 */
export const jumpPage = async <T extends ENUM_PAGE>(
    path: T,
    options: PageConfig[ENUM_PAGE] & JumpPageDefaultOptions = {}
) => {
    const islocalDev = Boolean(window.location.port);
    const isCurProject = path.startsWith(`${ROUTER_BASENAME}`);
    const { replace = false } = options;
    const locationOrigin = window.location.origin;
    // 当前单页跳转
    if (isCurProject) {
        let _path = path.replace(`${ROUTER_BASENAME}`, '');
        if ('query' in options && options.query) {
            _path = `${_path}?${qs.stringify(options.query)}`;
        }
        if (options.isNewPage === true) {
            // return window.open(`${ROUTER_BASENAME}${_path}`);
            return window.open(urlAddChannel(`${ROUTER_BASENAME}${_path}`));
        }
        getRouter().navigate(_path, {
            replace
        });
        return;
    }
    let _path = `${path}`;
    if ('query' in options) {
        _path = `${_path}?${qs.stringify(options.query)}`;
    }
    if (options.isNewPage) {
        if (islocalDev) {
            // return window.open(_path);
            return window.open(urlAddChannel(_path));
        }
        // return window.open(`${locationOrigin}${_path}`);
        return window.open(urlAddChannel(`${locationOrigin}${_path}`));
    }
    if (islocalDev) {
        window.location.href = urlAddChannel(_path);
    }
    if (_path.includes('http://') || _path.includes('https://')) {
        window.location.href = urlAddChannel(`${_path}`);
    } else {
        window.location.href = urlAddChannel(`${locationOrigin}${_path}`);
    }
};
enum ENUM_PICK_MAP_TYPE {
    /** 选择 map 中部分 key 映射到 options */
    PICK = 1,
    /** 剔除 map 中部分 key 映射到 options */
    OMIT = 2,
    /** 所有 map 中 key 映射到 options */
    ALL = 3
}
const originMapToOptions =
    <T extends Map<any, any>>(map: T) =>
    (type: ENUM_PICK_MAP_TYPE) =>
    (keys: GetKeyByMap<T>[]) =>
    <Fn extends (val: GetValueByMap<T>[0]) => any>(
        mapValue?: Fn
    ): {
        value: GetKeyByMap<T>;
        label: typeof mapValue extends undefined
            ? GetKeyByMap<T>
            : ReturnType<Fn>;
    }[] => {
        const list = Array.from(map.entries()).filter(([value]) => {
            if (type === ENUM_PICK_MAP_TYPE.OMIT && !keys.includes(value))
                return true;
            if (type === ENUM_PICK_MAP_TYPE.PICK && keys.includes(value))
                return true;
            if (type === ENUM_PICK_MAP_TYPE.ALL) return true;
            return false;
        });
        return list.map(([value, label]) => {
            const _label = mapValue ? mapValue(label) : label;
            return {
                value,
                label: _label
            };
        });
    };

/**
 * map options
 * @param map
 * @param mapValue
 * @returns
 */
export const mapToOptions = <
    T extends Map<any, any>,
    Fn extends (val: GetValueByMap<T>) => any
>(
    map: T,
    mapValue?: Fn
) => {
    return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.ALL)([])(mapValue);
};
export const mapToOptionsByOmitKeys = <
    T extends Map<any, any>,
    Fn extends (val: GetValueByMap<T>[0]) => any
>(
    map: T,
    keys: GetKeyByMap<T>[],
    mapValue?: Fn
) => {
    return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.OMIT)(keys)(mapValue);
};
export const mapToOptionsByPickKeys = <
    T extends Map<any, any>,
    Fn extends (val: GetValueByMap<T>[0]) => any
>(
    map: T,
    keys: GetKeyByMap<T>[],
    mapValue?: Fn
) => {
    return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.PICK)(keys)(mapValue);
};

/**
 * 获取查询字符串中的值
 * @param name query string 的 key
 * @returns value
 */
export const getQueryStringValueByName = (name: string) => {
    return new URLSearchParams(window.location.search).get(name);
};
export const secureJSONParse = <T>(str: any, defaultValue?: T) => {
    type SecureJSONParseStore = [Error | undefined, T | undefined];
    const res: SecureJSONParseStore = [undefined, undefined];
    try {
        const obj = JSON.parse(str);
        res[1] = obj ?? defaultValue;
    } catch (e) {
        res[0] = e as Error;
        if (defaultValue) res[1] = defaultValue;
    }
    return res;
};
export interface LocalStorageData {
    /** 用户 token */
    'production_route/token'?: string;
}
export enum ENUM_LOCAL_STORAGE_KEY {
    /** 用户 token */
    TOKEN = `production_route/token`
}
export const getSessionStorageByKey = <T>(key: string, defaultValue?: T) => {
    const jsonString = window.sessionStorage.getItem(key);
    return secureJSONParse(jsonString, defaultValue);
};
export const getLocalStorageByKey = <K extends keyof LocalStorageData, T>(
    key: string,
    defaultValue?: LocalStorageData[K]
) => {
    const jsonString = window.localStorage.getItem(key);
    return secureJSONParse(jsonString, defaultValue);
};

/**
 * 下载文件
 * @param res
 */
export const downloadFile = (data: BlobPart, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
};
export const formatDateJP2CN = (date?: dayjs.Dayjs | string): string => {
    return date
        ? dayjs(date).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
        : '';
};
export const formatDateCN2JP = (date?: dayjs.Dayjs | string): string => {
    return date ? dayjs(date).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss') : '';
};
export const getCNTime = () => {
    return dayjs.tz(dayjs());
};
export const getNumberSplit = (num?: string | number) => {
    if (!num) return 0;
    const reg = /(?=\B(\d{3})+$)/g;
    return `${num}`.replace(reg, ',');
};
export const platRedirect = async () => {
    if (window.screen?.availWidth < 700) {
        window.location.href = toH5TheCkb();
    }
};

// 文件转换为base64
export const searchByBase64 = (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        let canvas;
        const URL = window.URL || window.webkitURL;
        const blob = URL.createObjectURL(file);
        const img = new Image();
        img.src = blob;
        img.onload = function () {
            const that = this as any;
            const rate = file.size > 200 * 1024 ? 0.5 : 1;
            const w = that.width * rate;
            const h = that.height * rate;
            // 生成canvas
            canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            // document.append(canvas)
            ctx.drawImage(that, 0, 0, w, h);
            const base64 = canvas.toDataURL('image/jpeg', rate);
            // }
            resolve({
                status: true,
                data: base64
            });
        };
        img.onerror = () => {
            resolve({
                status: false
            });
        };
    });
};
/** 压缩之后的base64 */
function changeUrlToBase64Compress({ url, size }) {
    return new Promise((resolve, reject) => {
        let canvas;
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
        img.onload = function () {
            const that = this as any;
            const rate = size > 200 * 1024 ? 0.5 : 1;
            const w = that.width * rate;
            const h = that.height * rate;
            // 生成canvas
            canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            // document.append(canvas)
            ctx.drawImage(that, 0, 0, w, h);
            const base64 = canvas.toDataURL('image/jpeg', rate);
            // }
            resolve({
                status: true,
                data: base64
            });
        };
        img.onerror = () => {
            resolve({
                status: false
            });
        };
    });
}
/** url转为base64 */
async function changeUrlToBase64(url) {
    try {
        // 获取文件大小
        const response = await fetch(url);
        const contentLength = response.headers.get('content-length');
        const fileSize = parseInt(contentLength!, 10);
        /** 压缩之后的 */
        const data: any = await changeUrlToBase64Compress({
            url,
            size: fileSize
        });
        if (!data.status) return '';
        return data.data;
    } catch (error) {
        // throw new Error('处理过程中出错: ' + error.message);
    }
}
/** base64图搜上传，返回imageId */
export const changeBase64ToImageId = async ({ url, request }) => {
    const data = await changeUrlToBase64(url);
    const uploadUrlRes = await request({
        // imageUrl: url,
        imageBase64Content: data.split('data:image/jpeg;base64,')[1]
    });
    return {
        imageId: uploadUrlRes.data ?? '',
        imageUrl: url
    };
};
