import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}
export default function CancelCircle(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const {
    size = '1em',
    width,
    height,
    spin,
    rtl,
    color,
    fill,
    stroke,
    className,
    ...rest
  } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return <svg ref={root} width={_width} height={_height} viewBox="0 0 32 32" preserveAspectRatio="xMidYMid meet" fill="none" role="presentation" xmlns="http://www.w3.org/2000/svg" className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()} {...rest}>
          <g><path fillOpacity=".4" d="M16 4C9.374 4 4 9.373 4 16s5.373 12 12 12c6.628 0 12-5.373 12-12S22.629 4 16 4ZM1.335 16c0-8.1 6.567-14.667 14.667-14.667S30.667 7.9 30.667 16s-6.566 14.667-14.666 14.667C7.9 30.667 1.334 24.1 1.334 16Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#fff" fill={_fill} /><path fillOpacity=".4" d="M20.714 13.171a1.333 1.333 0 1 0-1.886-1.885L16 14.114l-2.827-2.828a1.333 1.333 0 1 0-1.886 1.885L14.115 16l-2.828 2.828a1.333 1.333 0 0 0 1.886 1.886L16 17.886l2.828 2.828a1.333 1.333 0 0 0 1.886-1.886L17.886 16l2.828-2.829Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#fff" fill={_fill} /></g>
        </svg>;
}