/*
 * @Author: shiguang
 * @Date: 2023-04-26 10:22:04
 * @LastEditors: lihwang
 * @LastEditTime: 2023-11-10 18:09:43
 * @Description: 56
 */

export enum ENUM_PICK_MAP_TYPE {
    /** 选择 map 中部分 key 映射到 options */
    PICK = 1,
    /** 剔除 map 中部分 key 映射到 options */
    OMIT = 2,
    /** 所有 map 中 key 映射到 options */
    ALL = 3
}
export enum ENUM_PAGE {
    /** 登录账号管理 */
    SETUP_ACCOUNT = '/vip/setupUser',
    /** oem 工作台 */
    WORKER_SPACE = '/user/UserCenter',
    /** oem 会员中心 */
    VIP_LEVEL = '/vip/VipLevel',
    /** 主页 */
    HOME = '/home',
    MY_ORDER = '/order/index',
    LOGIN = '/dev/login',
    LIST = '/list'
}

/** 系统类型 1-D2C; 2-B2B （后端字段 system_source） */
export enum ENUM_SYSTEM_SOURCE {
    /** D2C */
    D2C = 1,
    /** B2B */
    B2B = 2
}

/** 主子账号类型 （后端字段 account_type） */
export enum ENUM_ACCOUNT_TYPE {
    /** 主账号 */
    PARENT = 1,
    /** 子账号 */
    CHILD = 2
}
export enum DIRECT_TYPE {
    /** 入账 */
    AMOUNT_IN = 'AMOUNT_IN',
    /** 出账 */
    AMOUNT_OUT = 'AMOUNT_OUT'
}
export enum BIZ_TYPE {
    /** 交易支付 */
    PAY = 'PAY',
    /** 充值 */
    RECHARGE = 'RECHARGE'
}
export enum RECHARGE_WAY_TYPE {
    /** 银行充值 */
    BANK = 'BANK',
    /** 钱包余额 */
    WALLET = 'WALLET'
}
export enum RECHARGE_STATUS_TYPE {
    /** 待初审 */
    BUSINESS = 'BUSINESS',
    /** 待终审 */
    FINAL = 'FINAL',
    /** 已拒绝 */
    REFUSE = 'REFUSE',
    /** 已通过 */
    SUCCESS = 'SUCCESS'
}

// 收藏
export enum FAVORITE_FLAG_TYPE {
    /** 已收藏 */
    TRUE = 1,
    /** 未收藏 */
    FALSE = 0
}
export enum PLAT_TYPE {
    /** 0-直采商品， */
    ZY = 0,
    /** 1-代采商品 */
    DC = 1
}

// 一元购
export enum ONE_BUY_FLAG_TYPE {
    /** 是 */
    TRUE = 1,
    /** 否 */
    FALSE = 0
}

// 发货状态
export enum ORDER_STATUS_TYPE {
    /** 待支付 */
    WAIT_PAY = 0,
    /** 已支付(待发货) */
    HAVE_PAY_WAIT_SEND = 1,
    /** 已发货 */
    HAVE__SEND = 2,
    /** 已取消 */
    CANCEL = 3,
    /** 已关闭 */
    CLOSE = 4
}
export enum PRODUCT_STATUS_TYPE {
    /** 0-待售卖 */
    PENDING_SALE = 0,
    /** 1-售卖中 */
    SELLING = 1,
    /** 2-待下架 */
    PENDING_OFFLINE = 2,
    /** 停止售卖 */
    STOP_SALE = 3,
    /** 4-删除 */
    DELETE = 4
}

// 购买渠道
export enum SOURCE_CHANEL {
    PC = 1,
    H5 = 2
}

//  账号类型
export enum ACCOUNT_TYPE {
    MAIN = 1,
    SUB = 2
}

// 商品类型
export enum PRODUCT_TYPE {
    // 0-线上，1-线下
    ON_LINE = 0,
    OFF_LINE = 1
}

// 支付方式
export enum PAY_WAY {
    WALLET = 'WALLET',
    PAYPAL = 'PAYPAL',
    PAYPAL_CREDIT = 'PAYPAL_CREDIT'
}
export type payway = keyof typeof PAY_WAY;

/** 页面配置 */
export interface PageConfig {
    [ENUM_PAGE.SETUP_ACCOUNT]: {};
    [ENUM_PAGE.WORKER_SPACE]: {};
    /** oem 会员中心 */
    [ENUM_PAGE.VIP_LEVEL]: {};
    [ENUM_PAGE.HOME]: {};
    [ENUM_PAGE.MY_ORDER]: {};
    [ENUM_PAGE.LOGIN]: {};
    [ENUM_PAGE.LIST]: {};
}
export {};
