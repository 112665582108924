/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * ActivityAreaMoreQuery
 * 活动专区更多查询
 */
export interface ActivityAreaMoreQuery {
    /**
     * 活动专区id
     * @format int64
     */
    areaId?: number;
    /** 排序 是否降序 */
    descendOrder?: boolean;
    /** 结束价格 */
    endPrice?: number;
    /** 是否需要置顶商品(默认不需要) */
    needTopProduct?: boolean;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 排序字段:0 综合 1 价格(默认) 2 时间
     * @format int32
     */
    sortType?: number;
    sorts?: ISortFieldEnum[];
    /** @format int32 */
    startIndex?: number;
    /** 开始价格 */
    startPrice?: number;
}

/**
 * ActivityAreaQuery
 * 活动专区查询请求
 */
export interface ActivityAreaQuery {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** 模块名称 */
    moduleName?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    sorts?: ISortFieldEnum[];
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /** @format int32 */
    startIndex?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
}

/**
 * ActivityAreaRespDTO
 * 活动专区信息
 */
export interface ActivityAreaRespDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 模块说明 */
    moduleDesc?: string;
    /** 模块名称 */
    moduleName?: string;
    /** 更多类目列表 */
    moreCategoryList?: FrontCategoryInfo[];
    /** 更多商品列表 */
    moreProductList?: ProductInfo[];
    /**
     * 更多类型 1:商品 2:类目
     * @format int32
     */
    moreType?: number;
    /**
     * 位置
     * @format int32
     */
    position?: number;
    /** 价格说明 */
    priceDesc?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 置顶商品列表 */
    topProductList?: ProductInfo[];
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * ActivityAreaVO
 * 活动专区vo
 */
export interface ActivityAreaVO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 模块说明 */
    moduleDesc?: string;
    /** 模块名称 */
    moduleName?: string;
    /**
     * 更多类型 1:商品 2:类目
     * @format int32
     */
    moreType?: number;
    /**
     * 位置
     * @format int32
     */
    position?: number;
    /** 价格说明 */
    priceDesc?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /** 站点code */
    stationCode?: string;
    /** 置顶商品列表 */
    topProductList?: ProductVO[];
}

/**
 * AddActivityAreaReqDTO
 * 新建活动专区请求
 */
export interface AddActivityAreaReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** 模块说明 */
    moduleDesc?: string;
    /** 模块名称 */
    moduleName?: string;
    /** 更多类目列表 */
    moreCategoryList?: FrontCategoryInfo[];
    /** 更多商品列表 */
    moreProductList?: ProductInfo[];
    /**
     * 更多类型 1:商品 2:类目
     * @format int32
     */
    moreType?: number;
    /**
     * 位置
     * @format int32
     */
    position?: number;
    /** 价格说明 */
    priceDesc?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 置顶商品列表 */
    topProductList?: ProductInfo[];
}

/**
 * AddBannerReqDTO
 * 新增banner请求
 */
export interface AddBannerReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 位置类型
     * @format int32
     */
    placeType?: number;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 标题 */
    title?: string;
}

/**
 * AddFreightTemplateReqDTO
 * 新建运费模板请求
 */
export interface AddFreightTemplateReqDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 说明 */
    description?: string;
    /** 模板明细列表 */
    detailList?: FreightTemplateDetailInfo[];
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * 商品范围 0:全部 1:指定
     * @format int32
     */
    goodsScope?: number;
    /** 模板名称 */
    name?: string;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
}

/**
 * AddPopupReqDTO
 * 新建弹窗请求
 */
export interface AddPopupReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
}

/**
 * AddRecommendReqDTO
 * 新建推荐请求
 */
export interface AddRecommendReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** 推荐商品列表 */
    productList?: ProductInfo[];
    /**
     * 推荐类型 1:商品
     * @format int32
     */
    recommendType?: number;
    /** 来源渠道 1:PC 2:H5 */
    sourceChannels?: number[];
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 策略名称 */
    strategyName?: string;
}

/**
 * AddSellingPriceReqDTO
 * 新建销售价请求
 */
export interface AddSellingPriceReqDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 说明 */
    description?: string;
    /** 导入文件url */
    importFileUrl?: string;
    /** 名称 */
    name?: string;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    /**
     * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
     * @format int32
     */
    priceType?: number;
    /** 适用范围为全部时使用!! 价格值 */
    priceValue?: number;
    /** 站点code */
    stationCode?: string;
}

/**
 * BackendCategoryInfo
 * 后台类目信息
 */
export interface BackendCategoryInfo {
    /** 一级类目code */
    firstCategoryCode?: string;
    /** 一级类目名称 */
    firstCategoryName?: string;
    /** 二级类目code */
    secondCategoryCode?: string;
    /** 二级类目名称 */
    secondCategoryName?: string;
    /** 三级类目code */
    thirdCategoryCode?: string;
    /** 三级类目名称 */
    thirdCategoryName?: string;
}

/**
 * BannerQuery
 * banner查询
 */
export interface BannerQuery {
    /**
     * 业务场景
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 位置类型
     * @format int32
     */
    placeType?: number;
    sorts?: ISortFieldEnum[];
    /**
     * 来源渠道
     * @format int32
     */
    sourceChannel?: number;
    /** @format int32 */
    startIndex?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态
     * @format int32
     */
    status?: number;
    /** 标题 */
    title?: string;
}

/**
 * BannerRespDTO
 * banner信息
 */
export interface BannerRespDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 位置类型
     * @format int32
     */
    placeType?: number;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 标题 */
    title?: string;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * DeleteSellingPriceDetailReqDTO
 * 删除销售价明细请求
 */
export interface DeleteSellingPriceDetailReqDTO {
    /** 销售价明细id列表 */
    detailIdList?: number[];
    /**
     * 销售价id
     * @format int64
     */
    sellingPriceId?: number;
}

/**
 * EditActivityAreaReqDTO
 * 编辑活动专区请求
 */
export interface EditActivityAreaReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 模块说明 */
    moduleDesc?: string;
    /** 模块名称 */
    moduleName?: string;
    /** 更多类目列表 */
    moreCategoryList?: FrontCategoryInfo[];
    /** 更多商品列表 */
    moreProductList?: ProductInfo[];
    /**
     * 更多类型 1:商品 2:类目
     * @format int32
     */
    moreType?: number;
    /**
     * 位置
     * @format int32
     */
    position?: number;
    /** 价格说明 */
    priceDesc?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 置顶商品列表 */
    topProductList?: ProductInfo[];
}

/**
 * EditBannerReqDTO
 * 编辑banner请求
 */
export interface EditBannerReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 位置类型
     * @format int32
     */
    placeType?: number;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 标题 */
    title?: string;
}

/** EditDetail */
export interface EditDetail {
    /**
     * 销售价明细id
     * @format int64
     */
    detailId?: number;
    /** 价格值 */
    priceValue?: number;
}

/**
 * EditFreightTemplateReqDTO
 * 编辑运费模板请求
 */
export interface EditFreightTemplateReqDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 说明 */
    description?: string;
    /** 模板明细列表 */
    detailList?: FreightTemplateDetailInfo[];
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * 商品范围 0:全部 1:指定
     * @format int32
     */
    goodsScope?: number;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 模板名称 */
    name?: string;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
}

/**
 * EditPopupReqDTO
 * 编辑弹窗请求
 */
export interface EditPopupReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
}

/**
 * EditRecommendReqDTO
 * 编辑推荐请求
 */
export interface EditRecommendReqDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 推荐商品列表 */
    productList?: ProductInfo[];
    /**
     * 推荐类型 1:商品
     * @format int32
     */
    recommendType?: number;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /** 来源渠道 1:PC 2:H5 */
    sourceChannels?: number[];
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 策略名称 */
    strategyName?: string;
}

/**
 * EditSellingPriceDetailReqDTO
 * 编辑销售价明细请求
 */
export interface EditSellingPriceDetailReqDTO {
    /** 销售价明细列表 */
    editDetailList?: EditDetail[];
    /**
     * 销售价id
     * @format int64
     */
    sellPriceId?: number;
}

/**
 * EditSellingPriceReqDTO
 * 编辑销售价请求
 */
export interface EditSellingPriceReqDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 说明 */
    description?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /** 导入文件url */
    importFileUrl?: string;
    /** 名称 */
    name?: string;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    /**
     * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
     * @format int32
     */
    priceType?: number;
    /** 适用范围为全部时使用!! 价格值 */
    priceValue?: number;
    /** 站点code */
    stationCode?: string;
}

/**
 * FreightAreaVO
 * 运费地区vo
 */
export interface FreightAreaVO {
    /**
     * 收货人城市id
     * @format int64
     */
    receiverCityId?: number;
    /** 收货人城市名称 */
    receiverCityName?: string;
    /**
     * 收货人国家id
     * @format int64
     */
    receiverCountryId?: number;
    /** 收货人国家名称 */
    receiverCountryName?: string;
    /**
     * 收货人省份id
     * @format int64
     */
    receiverProvinceId?: number;
    /** 收货人省份名称 */
    receiverProvinceName?: string;
    /**
     * 收货人区域名称
     * @example "沖縄, 九州"
     */
    receiverRegionName?: string;
    /**
     * 发货人城市id
     * @format int64
     */
    senderCityId?: number;
    /** 发货人城市名称 */
    senderCityName?: string;
    /**
     * 发货人国家id
     * @format int64
     */
    senderCountryId?: number;
    /** 发货人国家名称 */
    senderCountryName?: string;
    /**
     * 发货人省份id
     * @format int64
     */
    senderProvinceId?: number;
    /** 发货人省份名称 */
    senderProvinceName?: string;
}

/**
 * FreightStageInfo
 * 运费阶梯信息
 */
export interface FreightStageInfo {
    /** 开始区间(包含) */
    from?: number;
    /** 定价 */
    price?: number;
    /** 结束区间(不包含) */
    to?: number;
}

/**
 * FreightTemplateDetailInfo
 * 运费模板明细信息
 */
export interface FreightTemplateDetailInfo {
    /**
     * 计费依据 0:订单金额
     * @format int32
     */
    billingBasic?: number;
    /**
     * 价格类型 0:固定价
     * @format int32
     */
    priceType?: number;
    /**
     * 收货人城市id
     * @format int64
     */
    receiverCityId?: number;
    /** 收货人城市名称 */
    receiverCityName?: string;
    /**
     * 收货人国家id
     * @format int64
     */
    receiverCountryId?: number;
    /** 收货人国家名称 */
    receiverCountryName?: string;
    /**
     * 收货人省份id
     * @format int64
     */
    receiverProvinceId?: number;
    /** 收货人省份名称 */
    receiverProvinceName?: string;
    /** 收货人区域名称 */
    receiverRegionName?: string;
    /**
     * 发货人城市id
     * @format int64
     */
    senderCityId?: number;
    /** 发货人城市名称 */
    senderCityName?: string;
    /**
     * 发货人国家id
     * @format int64
     */
    senderCountryId?: number;
    /** 发货人国家名称 */
    senderCountryName?: string;
    /**
     * 发货人省份id
     * @format int64
     */
    senderProvinceId?: number;
    /** 发货人省份名称 */
    senderProvinceName?: string;
    /** 运费阶梯列表 */
    stageList?: FreightStageInfo[];
}

/**
 * FreightTemplateDetailRespDTO
 * 运费模板明细信息
 */
export interface FreightTemplateDetailRespDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 计费依据 0:订单金额
     * @format int32
     */
    billingBasic?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * 商品范围 0:全部 1:指定
     * @format int32
     */
    goodsScope?: number;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /**
     * 价格类型 0:固定价
     * @format int32
     */
    priceType?: number;
    /**
     * 收货人城市id
     * @format int64
     */
    receiverCityId?: number;
    /** 收货人城市名称 */
    receiverCityName?: string;
    /**
     * 收货人国家id
     * @format int64
     */
    receiverCountryId?: number;
    /** 收货人国家名称 */
    receiverCountryName?: string;
    /**
     * 收货人省份id
     * @format int64
     */
    receiverProvinceId?: number;
    /** 收货人省份名称 */
    receiverProvinceName?: string;
    /** 收货人区域名称 */
    receiverRegionName?: string;
    /**
     * 发货人城市id
     * @format int64
     */
    senderCityId?: number;
    /** 发货人城市名称 */
    senderCityName?: string;
    /**
     * 发货人国家id
     * @format int64
     */
    senderCountryId?: number;
    /** 发货人国家名称 */
    senderCountryName?: string;
    /**
     * 发货人省份id
     * @format int64
     */
    senderProvinceId?: number;
    /** 发货人省份名称 */
    senderProvinceName?: string;
    /** 阶梯信息 */
    stageInfo?: string;
    /** 运费阶梯列表 */
    stageList?: FreightStageInfo[];
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:未生效 1:生效中 2:已失效
     * @format int32
     */
    status?: number;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * FreightTemplateQuery
 * 运费模板查询
 */
export interface FreightTemplateQuery {
    /**
     * 业务场景
     * @format int32
     */
    bizScene?: number;
    /** 是否校验有效 */
    checkValid?: boolean;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    sorts?: ISortFieldEnum[];
    /** @format int32 */
    startIndex?: number;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:未生效 1:生效中 2:已失效
     * @format int32
     */
    status?: number;
}

/**
 * FreightTemplateRespDTO
 * 运费模板信息
 */
export interface FreightTemplateRespDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 说明 */
    description?: string;
    /** 模板明细列表 */
    detailList?: FreightTemplateDetailRespDTO[];
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * 商品范围 0:全部 1:指定
     * @format int32
     */
    goodsScope?: number;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 模板名称 */
    name?: string;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:未生效 1:生效中 2:已失效
     * @format int32
     */
    status?: number;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * FrontCategoryInfo
 * 前台类目信息
 */
export interface FrontCategoryInfo {
    /**
     * 类目id
     * @format int64
     */
    categoryId?: number;
    /** 类目名称 */
    categoryName?: string;
}

/** ISortFieldEnum */
export interface ISortFieldEnum {
    asc?: boolean;
    field?: string;
}

/** OrderItem */
export interface OrderItem {
    asc?: boolean;
    column?: string;
}

/**
 * PopupQuery
 * 弹窗查询请求
 */
export interface PopupQuery {
    /**
     * 业务场景
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    sorts?: ISortFieldEnum[];
    /**
     * 来源渠道
     * @format int32
     */
    sourceChannel?: number;
    /** @format int32 */
    startIndex?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态
     * @format int32
     */
    status?: number;
}

/**
 * PopupRespDTO
 * 弹窗信息
 */
export interface PopupRespDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 跳转url */
    jumpUrl?: string;
    /** 图片url */
    picUrl?: string;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * ProductInfo
 * 商品信息
 */
export interface ProductInfo {
    /** 商品最高基准价格 */
    baseHighestPrice?: number;
    /** 商品最低基准价格 */
    baseLowestPrice?: number;
    /** 商品编码 */
    productCode?: string;
    /** 直采商详链接 */
    productDetailUrl?: string;
    /** 主图 */
    productMainImg?: string;
    /** 阶梯价格 */
    productPriceLadderList?: ProductPriceLadderInfo[];
    /** 商品最低销售价格 */
    productSellLowestPrice?: number;
    /** 商品标题 */
    productTitle?: string;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
     * @format int32
     */
    status?: number;
}

/**
 * ProductPriceLadderInfo
 * 商品阶梯价信息
 */
export interface ProductPriceLadderInfo {
    /** app价格 */
    appPrice?: number;
    /** 基准价 */
    basePrice?: number;
    /**
     * 阶梯数量
     * @format int32
     */
    count?: number;
    /** 国际运费 */
    internationalFreight?: number;
    /** 原始价格 */
    originPrice?: number;
    /** 溢价率 */
    premiumRate?: number;
    /** pc价格 */
    price?: number;
    /** 销售单价(参考价) */
    productSellPrice?: number;
    /** 销售价格 */
    sellPrice?: number;
}

/**
 * ProductVO
 * 商品vo
 */
export interface ProductVO {
    /**
     * 可用库存
     * @format int32
     */
    availableQuantity?: number;
    /** 平台类型 TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
    platformType?: string;
    /** 商品SPU */
    productCode?: string;
    /** 商品最低价 */
    productLowestPrice?: number;
    /** 商品主图 */
    productMainImg?: string;
    /** 商品名称 */
    productTitle?: string;
    /**
     * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
     * @format int32
     */
    status?: number;
}

/**
 * RecommendQuery
 * 推荐查询
 */
export interface RecommendQuery {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 推荐类型 1:商品
     * @format int32
     */
    recommendType?: number;
    sorts?: ISortFieldEnum[];
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /** @format int32 */
    startIndex?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 策略名称 */
    strategyName?: string;
}

/**
 * RecommendRespDTO
 * 推荐信息
 */
export interface RecommendRespDTO {
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * 结束时间
     * @format date-time
     */
    endTime?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 推荐商品列表 */
    productList?: ProductInfo[];
    /**
     * 推荐类型 1:商品
     * @format int32
     */
    recommendType?: number;
    /**
     * 来源渠道 1:PC 2:H5
     * @format int32
     */
    sourceChannel?: number;
    /**
     * 开始时间
     * @format date-time
     */
    startTime?: string;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /** 策略名称 */
    strategyName?: string;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * SellingPriceDetailQuery
 * 销售价明细查询
 */
export interface SellingPriceDetailQuery {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /** 是否包含已删除数据 */
    containDeleted?: boolean;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    /** 商品spu */
    productCode?: string;
    /** 商品名称 */
    productName?: string;
    /**
     * 销售价id
     * @format int64
     */
    sellingPriceId?: number;
    sorts?: ISortFieldEnum[];
    /** @format int32 */
    startIndex?: number;
    /** 站点code */
    stationCode?: string;
    /** 三级类目code */
    thirdCategoryCode?: string;
    /** 三级类目名称 */
    thirdCategoryName?: string;
    /**
     * 更新时间开始
     * @format date-time
     */
    updateTimeBegin?: string;
    /**
     * 更新时间结束
     * @format date-time
     */
    updateTimeEnd?: string;
}

/**
 * SellingPriceDetailRespDTO
 * 销售价明细信息
 */
export interface SellingPriceDetailRespDTO {
    /** 适用类目 */
    applyCategory?: BackendCategoryInfo;
    /** 适用信息 */
    applyInfo?: string;
    /** 适用商品 */
    applyProduct?: ProductInfo;
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 类目code */
    categoryCode?: string;
    /** 类目名称 */
    categoryName?: string;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    /**
     * 价格类型 0:固定价 1:溢价率
     * @format int32
     */
    priceType?: number;
    /** 价格值 */
    priceValue?: number;
    /** 商品spu */
    productCode?: string;
    /** 商品名称 */
    productName?: string;
    /**
     * 销售价id
     * @format int64
     */
    sellingPriceId?: number;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/**
 * SellingPriceQuery
 * 销售价查询
 */
export interface SellingPriceQuery {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /** 名称 */
    name?: string;
    /** @format int32 */
    pageNum?: number;
    /** @format int32 */
    pageSize?: number;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    sorts?: ISortFieldEnum[];
    /** @format int32 */
    startIndex?: number;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
}

/**
 * SellingPriceRespDTO
 * 销售价信息
 */
export interface SellingPriceRespDTO {
    /**
     * 适用范围 0:全部 1:指定
     * @format int32
     */
    applyScope?: number;
    /**
     * 业务场景 1:直营商城
     * @format int32
     */
    bizScene?: number;
    /**
     * 创建时间
     * @format date-time
     */
    createTime?: string;
    /** 创建人id */
    creatorId?: string;
    /** 创建人名称 */
    creatorName?: string;
    /** 说明 */
    description?: string;
    /** 修改人id */
    editorId?: string;
    /** 修改人名称 */
    editorName?: string;
    /**
     * id
     * @format int64
     */
    id?: number;
    /**
     * 是否删除 0:未删除 1:已删除
     * @format int32
     */
    isDeleted?: number;
    /** 名称 */
    name?: string;
    /**
     * 价格维度 0:类目 1:商品 2:sku
     * @format int32
     */
    priceDimension?: number;
    /**
     * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
     * @format int32
     */
    priceType?: number;
    /** 适用范围为全部时使用!! 价格值 */
    priceValue?: number;
    /** 站点code */
    stationCode?: string;
    /**
     * 状态 0:关闭 1:开启
     * @format int32
     */
    status?: number;
    /**
     * 修改时间
     * @format date-time
     */
    updateTime?: string;
}

/** BizResponse«ActivityAreaRespDTO» */
export interface BizResponseActivityAreaRespDTO {
    code?: string;
    /** 活动专区信息 */
    data?: ActivityAreaRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ActivityAreaVO»» */
export interface BizResponseListActivityAreaVO {
    code?: string;
    data?: ActivityAreaVO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«BannerRespDTO»» */
export interface BizResponseListBannerRespDTO {
    code?: string;
    data?: BannerRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«FreightAreaVO»» */
export interface BizResponseListFreightAreaVO {
    code?: string;
    data?: FreightAreaVO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«FreightTemplateDetailRespDTO»» */
export interface BizResponseListFreightTemplateDetailRespDTO {
    code?: string;
    data?: FreightTemplateDetailRespDTO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«List«ProductVO»» */
export interface BizResponseListProductVO {
    code?: string;
    data?: ProductVO[];
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«ActivityAreaRespDTO»» */
export interface BizResponsePageActivityAreaRespDTO {
    code?: string;
    data?: PageActivityAreaRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«BannerRespDTO»» */
export interface BizResponsePageBannerRespDTO {
    code?: string;
    data?: PageBannerRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«FreightTemplateRespDTO»» */
export interface BizResponsePageFreightTemplateRespDTO {
    code?: string;
    data?: PageFreightTemplateRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«PopupRespDTO»» */
export interface BizResponsePagePopupRespDTO {
    code?: string;
    data?: PagePopupRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«ProductVO»» */
export interface BizResponsePageProductVO {
    code?: string;
    data?: PageProductVO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«RecommendRespDTO»» */
export interface BizResponsePageRecommendRespDTO {
    code?: string;
    data?: PageRecommendRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«SellingPriceDetailRespDTO»» */
export interface BizResponsePageSellingPriceDetailRespDTO {
    code?: string;
    data?: PageSellingPriceDetailRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«Page«SellingPriceRespDTO»» */
export interface BizResponsePageSellingPriceRespDTO {
    code?: string;
    data?: PageSellingPriceRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«PopupRespDTO» */
export interface BizResponsePopupRespDTO {
    code?: string;
    /** 弹窗信息 */
    data?: PopupRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«RecommendRespDTO» */
export interface BizResponseRecommendRespDTO {
    code?: string;
    /** 推荐信息 */
    data?: RecommendRespDTO;
    msg?: string;
    success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
    code?: string;
    data?: object;
    msg?: string;
    success?: boolean;
}

/** Page«ActivityAreaRespDTO» */
export interface PageActivityAreaRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: ActivityAreaRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«BannerRespDTO» */
export interface PageBannerRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: BannerRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«FreightTemplateRespDTO» */
export interface PageFreightTemplateRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: FreightTemplateRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«PopupRespDTO» */
export interface PagePopupRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: PopupRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«ProductVO» */
export interface PageProductVO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: ProductVO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«RecommendRespDTO» */
export interface PageRecommendRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: RecommendRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«SellingPriceDetailRespDTO» */
export interface PageSellingPriceDetailRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: SellingPriceDetailRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

/** Page«SellingPriceRespDTO» */
export interface PageSellingPriceRespDTO {
    countId?: string;
    /** @format int64 */
    current?: number;
    hitCount?: boolean;
    /** @format int64 */
    maxLimit?: number;
    optimizeCountSql?: boolean;
    orders?: OrderItem[];
    /** @format int64 */
    pages?: number;
    records?: SellingPriceRespDTO[];
    searchCount?: boolean;
    /** @format int64 */
    size?: number;
    /** @format int64 */
    total?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/market'
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path
            })
            .then((response) => response.data);
    };
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/market
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    probe = {
        /**
         * No description
         *
         * @tags livens-probe-controller
         * @name Test
         * @summary 执行一次健康检查探针
         * @request GET:/Probe/test
         */
        test: (params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/Probe/test`,
                method: 'GET',
                ...params
            })
    };
    activity = {
        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaAdd
         * @summary 新建活动专区
         * @request POST:/activity/area/add
         */
        areaAdd: (reqDTO: AddActivityAreaReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/activity/area/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaDelete
         * @summary 删除活动专区
         * @request DELETE:/activity/area/delete
         */
        areaDelete: (
            query: {
                /**
                 * areaId
                 * @format int64
                 */
                areaId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/activity/area/delete`,
                method: 'DELETE',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaDetail
         * @summary 查询活动专区详情
         * @request GET:/activity/area/detail
         */
        areaDetail: (
            query: {
                /**
                 * areaId
                 * @format int64
                 */
                areaId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseActivityAreaRespDTO, any>({
                path: `/activity/area/detail`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaEdit
         * @summary 编辑活动专区
         * @request POST:/activity/area/edit
         */
        areaEdit: (reqDTO: EditActivityAreaReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/activity/area/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaList
         * @summary 活动专区列表(前台)
         * @request GET:/activity/area/list
         */
        areaList: (
            query?: {
                /** 业务场景 1:直营商城 */
                bizScene?: string;
                /** 来源渠道 1:PC 2:H5 */
                sourceChannel?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseListActivityAreaVO, any>({
                path: `/activity/area/list`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaMoreProduct
         * @summary 活动专区更多商品(前台)
         * @request POST:/activity/area/moreProduct
         */
        areaMoreProduct: (query: ActivityAreaMoreQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageProductVO, any>({
                path: `/activity/area/moreProduct`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaPage
         * @summary 分页查询活动专区
         * @request POST:/activity/area/page
         */
        areaPage: (query: ActivityAreaQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageActivityAreaRespDTO, any>({
                path: `/activity/area/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 活动专区
         * @name AreaToggleStatus
         * @summary 开启/关闭活动专区状态
         * @request GET:/activity/area/toggle/status
         */
        areaToggleStatus: (
            query: {
                /**
                 * areaId
                 * @format int64
                 */
                areaId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/activity/area/toggle/status`,
                method: 'GET',
                query: query,
                ...params
            })
    };
    banner = {
        /**
         * No description
         *
         * @tags banner
         * @name PostBanner
         * @summary 新建banner
         * @request POST:/banner/add
         */
        postBanner: (reqDTO: AddBannerReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/banner/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags banner
         * @name Delete
         * @summary 删除banner
         * @request DELETE:/banner/delete
         */
        delete: (
            query: {
                /**
                 * bannerId
                 * @format int64
                 */
                bannerId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/banner/delete`,
                method: 'DELETE',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags banner
         * @name Edit
         * @summary 编辑banner
         * @request POST:/banner/edit
         */
        edit: (reqDTO: EditBannerReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/banner/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags banner
         * @name Page
         * @summary 分页查询banner
         * @request POST:/banner/page
         */
        page: (query: BannerQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageBannerRespDTO, any>({
                path: `/banner/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags banner
         * @name Show
         * @summary 获取用户显示的banner(前台)
         * @request GET:/banner/show
         */
        show: (
            query?: {
                /** 业务场景 1:直营商城 */
                bizScene?: string;
                /** 来源渠道 1:PC 2:H5 */
                sourceChannel?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseListBannerRespDTO, any>({
                path: `/banner/show`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags banner
         * @name ToggleStatus
         * @summary 开启/关闭banner状态
         * @request GET:/banner/toggle/status
         */
        toggleStatus: (
            query: {
                /**
                 * bannerId
                 * @format int64
                 */
                bannerId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/banner/toggle/status`,
                method: 'GET',
                query: query,
                ...params
            })
    };
    freight = {
        /**
         * No description
         *
         * @tags 运费模板
         * @name TemplateAdd
         * @summary 新建运费模板
         * @request POST:/freight/template/add
         */
        templateAdd: (reqDTO: AddFreightTemplateReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/freight/template/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 运费模板
         * @name TemplateEdit
         * @summary 编辑运费模板
         * @request POST:/freight/template/edit
         */
        templateEdit: (reqDTO: EditFreightTemplateReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/freight/template/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 运费模板
         * @name TemplateInitArea
         * @summary 初始化运费地区
         * @request GET:/freight/template/initArea
         */
        templateInitArea: (params: RequestParams = {}) =>
            this.request<BizResponseListFreightAreaVO, any>({
                path: `/freight/template/initArea`,
                method: 'GET',
                ...params
            }),

        /**
         * No description
         *
         * @tags 运费模板
         * @name TemplatePage
         * @summary 分页查询运费模板
         * @request POST:/freight/template/page
         */
        templatePage: (query: FreightTemplateQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageFreightTemplateRespDTO, any>({
                path: `/freight/template/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 运费模板
         * @name TemplateShow
         * @summary 展示运费活动(前台)
         * @request GET:/freight/template/show
         */
        templateShow: (params: RequestParams = {}) =>
            this.request<BizResponseListFreightTemplateDetailRespDTO, any>({
                path: `/freight/template/show`,
                method: 'GET',
                ...params
            })
    };
    popup = {
        /**
         * No description
         *
         * @tags 弹窗
         * @name PostPopup
         * @summary 新建弹窗
         * @request POST:/popup/add
         */
        postPopup: (reqDTO: AddPopupReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/popup/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 弹窗
         * @name Delete
         * @summary 删除弹窗
         * @request DELETE:/popup/delete
         */
        delete: (
            query: {
                /**
                 * popupId
                 * @format int64
                 */
                popupId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/popup/delete`,
                method: 'DELETE',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 弹窗
         * @name Edit
         * @summary 编辑弹窗
         * @request POST:/popup/edit
         */
        edit: (reqDTO: EditPopupReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/popup/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 弹窗
         * @name Page
         * @summary 分页查询弹窗
         * @request POST:/popup/page
         */
        page: (query: PopupQuery, params: RequestParams = {}) =>
            this.request<BizResponsePagePopupRespDTO, any>({
                path: `/popup/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 弹窗
         * @name Show
         * @summary 获取用户显示的弹窗(前台)
         * @request GET:/popup/show
         */
        show: (
            query?: {
                /** 业务场景 1:直营商城 */
                bizScene?: string;
                /** 来源渠道 1:PC 2:H5 */
                sourceChannel?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponsePopupRespDTO, any>({
                path: `/popup/show`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 弹窗
         * @name ToggleStatus
         * @summary 开启/关闭弹窗状态
         * @request GET:/popup/toggle/status
         */
        toggleStatus: (
            query: {
                /**
                 * popupId
                 * @format int64
                 */
                popupId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/popup/toggle/status`,
                method: 'GET',
                query: query,
                ...params
            })
    };
    recommend = {
        /**
         * No description
         *
         * @tags 推荐
         * @name PostRecommend
         * @summary 新建推荐
         * @request POST:/recommend/add
         */
        postRecommend: (reqDTO: AddRecommendReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/recommend/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name Delete
         * @summary 删除推荐
         * @request DELETE:/recommend/delete
         */
        delete: (
            query: {
                /**
                 * recommendId
                 * @format int64
                 */
                recommendId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/recommend/delete`,
                method: 'DELETE',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name Detail
         * @summary 查询推荐详情
         * @request GET:/recommend/detail
         */
        detail: (
            query: {
                /**
                 * recommendId
                 * @format int64
                 */
                recommendId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseRecommendRespDTO, any>({
                path: `/recommend/detail`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name Edit
         * @summary 编辑推荐
         * @request POST:/recommend/edit
         */
        edit: (reqDTO: EditRecommendReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/recommend/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name Page
         * @summary 分页查询推荐
         * @request POST:/recommend/page
         */
        page: (query: RecommendQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageRecommendRespDTO, any>({
                path: `/recommend/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name Show
         * @summary 获取推荐列表(前台)
         * @request GET:/recommend/show
         */
        show: (
            query?: {
                /** 业务场景 1:直营商城 */
                bizScene?: string;
                /** 来源渠道 1:PC 2:H5 */
                sourceChannel?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseListProductVO, any>({
                path: `/recommend/show`,
                method: 'GET',
                query: query,
                ...params
            }),

        /**
         * No description
         *
         * @tags 推荐
         * @name ToggleStatus
         * @summary 开启/关闭推荐状态
         * @request GET:/recommend/toggle/status
         */
        toggleStatus: (
            query: {
                /**
                 * recommendId
                 * @format int64
                 */
                recommendId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BizResponseObject, any>({
                path: `/recommend/toggle/status`,
                method: 'GET',
                query: query,
                ...params
            })
    };
    selling = {
        /**
         * No description
         *
         * @tags 销售价
         * @name PriceAdd
         * @summary 新建销售价配置
         * @request POST:/selling/price/add
         */
        priceAdd: (reqDTO: AddSellingPriceReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/selling/price/add`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 销售价
         * @name PriceDeleteDetail
         * @summary 删除销售价明细
         * @request POST:/selling/price/deleteDetail
         */
        priceDeleteDetail: (reqDTO: DeleteSellingPriceDetailReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/selling/price/deleteDetail`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 销售价
         * @name PriceDetailList
         * @summary 查询销售价明细列表
         * @request POST:/selling/price/detailList
         */
        priceDetailList: (query: SellingPriceDetailQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageSellingPriceDetailRespDTO, any>({
                path: `/selling/price/detailList`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 销售价
         * @name PriceEdit
         * @summary 编辑销售价配置
         * @request POST:/selling/price/edit
         */
        priceEdit: (reqDTO: EditSellingPriceReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/selling/price/edit`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 销售价
         * @name PriceEditDetail
         * @summary 编辑销售价明细
         * @request POST:/selling/price/editDetail
         */
        priceEditDetail: (reqDTO: EditSellingPriceDetailReqDTO, params: RequestParams = {}) =>
            this.request<BizResponseObject, any>({
                path: `/selling/price/editDetail`,
                method: 'POST',
                body: reqDTO,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags 销售价
         * @name PricePage
         * @summary 分页销售价配置
         * @request POST:/selling/price/page
         */
        pricePage: (query: SellingPriceQuery, params: RequestParams = {}) =>
            this.request<BizResponsePageSellingPriceRespDTO, any>({
                path: `/selling/price/page`,
                method: 'POST',
                body: query,
                type: ContentType.Json,
                ...params
            })
    };
}
