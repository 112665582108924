/*
 * @Author: shiguang
 * @Date: 2023-04-28 12:30:18
 * @LastEditors: lihwang
 * @LastEditTime: 2024-01-16 18:42:31
 * @Description: CKBLayout
 */

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import FloatToolbar from '@/components/FloatToolbar';
import { CountriesEnum, Site } from '@/const';
import { checkLogin, isLogin } from '@/config/request/login';
import { atomRequestCustomerDetail } from '@/App';
import CKBHeader from './modules/CKBHeader';
import AgentToDirectLoading from './modules/AgentToDirectLoading';
import MenuList from './modules/MenuList';
import './index.css';
export interface CKBLayoutProps {
    children?: React.ReactNode;
    isShowMenu?: boolean;
}
const CKBLayout: React.FC<CKBLayoutProps> = (props: CKBLayoutProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [customerDetail] = useAtom(atomRequestCustomerDetail);
    const loginStatus = isLogin();
    const { children, isShowMenu } = props;
    useEffect(() => {
        const stationCode = localStorage.getItem('stationCode') as string;
        console.log(
            'CKBLayout stationCode',
            stationCode,
            loginStatus,
            customerDetail?.isJA
        );
    }, [customerDetail?.isJA, loginStatus, navigate, pathname]);
    return (
        <div className="bg-[#F0F2F5]">
            <AgentToDirectLoading />
            {/* <CKBHeader /> */}
            <FloatToolbar />
            <div
                className="flex"
                style={{
                    boxSizing: 'border-box',
                    minHeight: '100vh'
                }}
            >
                {isShowMenu !== false && <MenuList />}
                <div
                    className="box-border"
                    id="box-border-container"
                    style={{
                        flexGrow: 2
                        // scrollPaddingTop: '400px'
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
export default CKBLayout;
